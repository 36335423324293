import React from 'react';
import ClientSection from './section/ClientSection';
import { whiteSpace } from './../../../../script/caracter/CaracterScript';
import { MenuSource } from './../../../../source/validation/menu/MenuValidation';

function Client(params) {
  const { isMobile } = params;
  const children = [
    <ClientSection key={0} isMobile={isMobile}></ClientSection>,
  ];

  return <div id={`${whiteSpace(MenuSource.OURCLIENTS)}`}>{children}</div>;
}

export default Client;
