import React from 'react';
import OurServiceSection from './section/OurServiceSection';
import { whiteSpace } from './../../../../script/caracter/CaracterScript';
import { MenuSource } from './../../../../source/validation/menu/MenuValidation';
import { connect } from 'react-redux';

function OurService(params) {
  const { isMobile, handleCurrent, handleRedirect,history, } = params;
  const children = [
    <OurServiceSection
      key={0}
      isMobile={isMobile}
      history={history}
      handleRedirect={handleRedirect}
      handleCurrent={handleCurrent}
    ></OurServiceSection>,
  ];

  return <div id={`${whiteSpace(MenuSource.SERVICE)}`}>{children}</div>;
}
const mapStateToProps = (state) => ({
  current: state.current,
});

const mapDispatchToProps = (dispatch) => ({
  handleCurrent(value) {
    dispatch({
      type: 'Add_Current',
      value,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(OurService);
