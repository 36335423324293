import { PageValidation } from '../../../validation/page/PageValidation';
import { PageImage } from '../../../image/PageImage';
import { PageForm } from './../../../../function/page/form/PageForm';

const col = { xs: 24, sm: 24, md: 24, lg: 24 };

export const formValue = {
  interest: 'interest',
  position: 'position',
  document: 'document',
  name: 'name',
  lastname: 'lastname',
  identification: 'identification',
  phone: 'phone',
  email: 'email',
  file: 'file',
  caption: 'caption',
  profile: 'profile',
  obs: 'observacion',
};

export const formValid = {
  [`${formValue.identification}`]: false,
  [`${formValue.email}`]: false,
};

export const JobFormSource = {
  recaptchaGenerate: 'Jobs',
  recent: 'Noticias destacadas',
  ancient: 'Noticias antiguas',
  content: `Almacenes Juan Eljuri División Licores te invita a formar parte de nuestro gran equipo.
  <br />
  Envía todos tus datos y aplica a nuestras vacantes para que seas tomado en cuenta en nuestros procesos de selección.
  <br />
  Entérate de nuestras vacantes en nuestra cuenta oficial de
  <a href='https://www.linkedin.com/company/3991581/' class='linkedin'>LinkedIn </a>`,
  submit: { button: 'Enviar' },
  submitlinkedin: { button: 'Registrarse con Linkedin' },
  api: 'PCr/VmSlPCr',
  formConstructor: [

    {
      id: 1,
      name: formValue.name,
      message: { error: '', rule: null },
      api: null,
      social: true,

      element: { type: PageForm.INPUT, placeholder: 'Nombres' },
      rule: { ...PageForm.REQUIRED },
      isNumeric: false,
      col: col,
    },
    {
      id: 2,
      name: formValue.lastname,
      message: { error: '', rule: null },
      api: null,
      social: true,

      element: { type: PageForm.INPUT, placeholder: 'Apellidos' },
      rule: { ...PageForm.REQUIRED },
      isNumeric: false,
      col: col,
    },
    {
      id: 3,
      name: formValue.email,
      message: { error: 'El email ya fue postulado *' },
      api: null, //PageApi._isEmail,
      social: true,
      element: { type: PageForm.INPUT, placeholder: 'Email' },
      rule: { ...PageForm.REQUIRED, type: 'email' },
      isNumeric: false,
      col: col,
    },
  
    {
      id: 4,
      name: formValue.phone,
      message: { error: '', rule: null },
      api: null,
      social: false,
      element: { type: PageForm.INPUT, placeholder: 'Teléfono' },
      rule: { ...PageForm.PHONE, max: 10, ...PageForm.REQUIRED },
      isNumeric: true,
      col: col,
    },

    {
      id: 5,
      name: formValue.obs,
      message: { error: '' },
      api: null, //PageApi._isEmail,
      social: true,
      element: { type: PageForm.TEXTAREA, placeholder: 'Observación' },
      rule: { ...PageForm.OBS },
      isNumeric: false,
      col: col,
    }
  ],
  skeletonInput: {
    width: '100%',
    height: '28',
    size: 'large',
    shape: 'square',
  },
};

export const JobSource = {
  col: { xs: 24, sm: 24, md: 24, lg: 24 },
  background: {
    img1: PageImage.jobs.Jbsimg0,
  },
  title: {
    content: `Forma parte de nuestro <br /> equipo de trabajo`,
    color: PageValidation.color0,
    align: PageValidation.center,
  },
};
