import React from 'react';
import { Row, Col } from 'antd';
import Carousel from 'react-multi-carousel';
import { CarouselValidation } from './../../source/validation/carousel/CarouselVaidation';
import { CarouselClasses } from './../../source/classes/carousel/CarouselClass';
import { PageStyle } from './../../source/style/page/PageStyle';
import { PageClasses } from './../../source/classes/page/PageClasses';
import 'react-multi-carousel/lib/styles.css';
import {
  Paragraph144RR,
  Paragraph096RL,
} from './../paragraph/ParagraphFunction';
import { ButtonActionModal } from '../button/ButtonAction';

export function CarouselFunction(params) {
  const { data, setOk, setModalDetail } = params;
  const { responsive } = CarouselValidation;

  const viewModal = (value) => {
    setOk(true);
    setModalDetail(value);
  };
  return (
    <Carousel responsive={responsive}>
      {data.map((item, index) => (
        <Row key={index}>
          <Col
            key={item.id}
            {...CarouselClasses.block}
            lg={22}
            md={20}
            sm={22}
            xs={22}
            {...PageStyle.col}
          >
            <div {...PageClasses.blockContent} {...PageStyle.padding7}>
              <Paragraph144RR
                value={item.title.content}
                color={item.title.color}
                align={item.title.align}
              ></Paragraph144RR>

              <Paragraph096RL
                value={item.content.content}
                align={item.content.align}
                color={item.content.color}
                expand={item.expand}
              ></Paragraph096RL>
              {item.expand && (
                <ButtonActionModal
                  onclick={viewModal}
                  item={item}
                ></ButtonActionModal>
              )}
            </div>
          </Col>
        </Row>
      ))}
    </Carousel>
  );
}
