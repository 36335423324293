export const PageValidation = {
  activo: 'ACTIVO',
  positionAbs: 'absolute',
  zIndex0: 0,
  zIndex1: 1,
  zIndex2: 2,
  width1: '100%',
  width90: '90%',
  width77: '77%',
  alt: 'AFS',
  unset: 'unset',
  fontFamily1: 'Roboto-Regular',
  fontFamily2: 'Roboto-Medium',
  fontFamily3: 'Roboto-Light',
  fontSize1: '0.96rem',
  fontSize2: '0.84rem',
  fontSize3: '2.2rem',
  fontSize4: '2.0rem',
  fontSize5: '1.44rem',
  fontSize6: '3.125rem',
  fontSize7: '1rem',
  fontSize8: '1.7rem',
  fontSize9: '1.2rem',
  fontSize10: '1.1rem',
  type: 'primary',
  shape: 'round',
  sizeButton: 'large',
  color0: '#ffffff',
  color1: '#231F20',
  color2: '#303030',
  color3: '#FF6C00',
  color4: '#1A8DFF',
  color5: '#FF5757',
  color6: '#5BCE12',
  color7: '#7B5CFF',
  color8: '#2D45AC',
  color9: '#FFC400',
  color10: '#52c41a',
  color11: '#C1C7D1',
  color12: '#613BFF',
  color13: '#B90000',
  color14: '#FF96E3',
  color15: '#9AA2AF',
  color16: '#888888',
  color17: '#C4C4C4',
  color18: '#77D939',
  color19: '#C43636',
  color20: '#59D100',
  color21: '#FAFAFA',
  color22: '#293642',

  colorprimary: '#293642',
  var1: 4,
  var2: 10,
  var3: 300,
  var4: 400,
  var5: 500,
  var6: 22,
  var7: 20,
  var8: 24,
  var9: 200,
  var10: 250,
  var11: 100,
  var12: 8,
  var13: 16,
  var14: 600,
  var15: 700,
  var16: 230,
  var17: 160,
  var18: 130,
  var19: 50,
  var20: 70,
  var21: 180,

  norepeat: 'no-repeat',
  center: 'center',
  end: 'end',
  justify: 'justify',
  start: 'start',
  left: 'left',
  cover: 'cover',
  contain: 'contain',
  translate0: 'translateX(100px)',
  email: 'afslorcacomercial@spainafs.com',
  readMore: 'Leer más',
  abrirmaps: 'Abrir en maps',
  link: 'link',
  card: 'card',
  span: 'span',
  tab: 'tab',
  flex: 'flex',
  list: 'list',
  carousel: 'carousel',
  item0: 'ERP BISS',
  item1: 'Aplicaciones Web',
  item2: 'Aplicaciones Móviles',
  item3: 'Business Inteligence',
  image: 'image',
  gallery: 'gallery',
};
