import { PageValidation } from './../../validation/page/PageValidation';
const unset = 'unset';
const whitespace = { whiteSpace: unset };

const var1 = {
  color: PageValidation.color1,
  fontFamily: PageValidation.fontFamily1,
  marginBottom: unset,
};

const var2 = {
  color: PageValidation.color1,
  fontFamily: PageValidation.fontFamily1,
};

export const MenuStyle = {
  menu: {
    style: {
      ...whitespace,
      textAlign: 'end',
      backgroundColor: PageValidation.color0,
      transition: PageValidation.transition1,
      ...var2,
    },
  },
  menuMobile: {
    style: {
      ...whitespace,
      textAlign: 'center',
      transition: PageValidation.transition1,
      ...var2,
    },
  },
  menuIcon: { style: { paddingLeft: unset, paddingRight: unset } },
  menuParagraph: {
    style: {
      ...var1,
      fontSize: '15px',
    },
  },
  menuParagraphMobile: {
    style: {
      ...var1,
      fontSize: '12px',
    },
  },
  menuLogo: { style: { maxWidth: '100%', width: '125px' } },
  menuLogoMobile: { style: { maxWidth: '100%', width: '100px' } },
  menuStatic: {
    style: {
      backgroundColor: PageValidation.color5,
      position: 'absolute',
      zIndex: 1,
      width: '100%',
    },
  },
  menuBar: {
    style: { padding: '5px 24px' },
  },
  menuBarContent: { style: { backgroundColor: PageValidation.color2 } },
};
