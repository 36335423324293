import React from 'react';
import FooterSection from './section/FooterSection';
import { whiteSpace } from './../../script/caracter/CaracterScript';
import { MenuSource } from './../../source/validation/menu/MenuValidation';

function Footer(params) {
  const { isMobile, itemMenu } = params;

  const children = [
    <FooterSection
      key={0}
      isMobile={isMobile}
      itemMenu={itemMenu}
    ></FooterSection>
  ];
  return <div id={`${whiteSpace(MenuSource.CONTACT)}`}>{children}</div>;
}

export default Footer;
