import React, { useEffect } from 'react';
import { PageStyle } from './../../source/style/page/PageStyle';
import { RowStyle } from './../../source/style/row/RowStyle';
import { PageValidation } from './../../source/validation/page/PageValidation';
import { PageClasses } from './../../source/classes/page/PageClasses';
import {
  Paragraph20RR,
  Paragraph096RL,
  Paragraph144RR,
  Paragraph1RR,
  Paragraph12RR,
  PageDetailCS084C
} from '../paragraph/ParagraphFunction';
import TweenOne from 'rc-tween-one';
import ScrollAnim from 'rc-scroll-anim';
import { PageAnimation } from './../../source/animation/page/PageAnimation';
import { PageDetail096RSMBS } from './../paragraph/ParagraphFunction';
import { SocialSCE } from './../social/SocialFunction';
import { Row, Col, Tabs, Card, Button } from 'antd';
import { CarouselClasses } from './../../source/classes/carousel/CarouselClass';
import { CarouselStyle } from './../../source/style/carousel/CarouselStyle';
import { ButtonActionModal, ButtonAction } from '../button/ButtonAction';
import { ListIconText } from './../list/ListFunction';
import { LeftOutlined } from '@ant-design/icons';
import { TitleText, TitleTextLine } from '../header/HeaderFunction';
import { ImageGallery } from '../image/ImageFunction';
import { RemoveBr } from '../script/Script';
import { MailOutlined, AntDesignOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { MissionSource } from './../../source/component/home/mission/MissionSource';
import { useHistory } from "react-router-dom";
import { whiteSpace } from './../../script/caracter/CaracterScript';
import { MenuSource } from './../../source/validation/menu/MenuValidation';
import { RouteUrl } from './../../source/route/RouteSource';

const ScrollOverPack = ScrollAnim.OverPack;
const { TabPane } = Tabs;

//Row Right 10%
export function RowR10(params) {
  const {
    isMobile,
    image,
    title,
    content,
    img,
    social,
    color,
    expand,
    setOk,
    setModalDetail,
  } = params;
  const imageSize = isMobile ? RowStyle.bannerI150 : RowStyle.bannerI500;
  const banneImage = {
    backgroundImage: `url(${image})`,
    ...imageSize.style,
  };

  const style = !isMobile ? PageStyle.maringR00010PR : null;
  const iscolor = !isMobile
    ? {
      style: {
        ...PageStyle.padding20P24P20P0P.style,
        backgroundColor: color ? color : PageValidation.color0,
      },
    }
    : { ...PageStyle.padding5 };
  const left = !isMobile && PageStyle.marginL10;

  const viewModal = () => {
    setOk(true);
    setModalDetail({ image, title, content });
  };

  return (
    <div {...PageClasses.page} {...iscolor}>
      <div {...style}>
        <Row {...PageClasses.justifyCenter} gutter={[24]} {...RowStyle.row}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div style={banneImage}></div>
          </Col>
          <Col xs={22} sm={22} md={12} lg={12}>
            <div {...left}>
              <Paragraph20RR
                img={img}
                value={title.content}
                color={title.color}
                align={title.align}
              ></Paragraph20RR>
              <br></br>
              <ScrollOverPack {...PageAnimation.scrollAnim.anim}>
                <TweenOne key="RowR10" {...PageAnimation.scrollAnim.anim2}>
                  <PageDetail096RSMBS
                    value={content}
                    color={PageValidation.color1}
                    align={PageValidation.justify}
                    expand={expand}
                  ></PageDetail096RSMBS>
                  <ButtonActionModal onclick={viewModal}></ButtonActionModal>
                </TweenOne>
                <TweenOne
                  key="RowR10Social"
                  {...PageAnimation.scrollAnim.anim5}
                >
                  {social && (
                    <div>
                      <SocialSCE
                        social={social}
                        isMobile={isMobile}
                        align={PageStyle.textAlignE}
                      ></SocialSCE>
                    </div>
                  )}
                </TweenOne>
              </ScrollOverPack>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
//Row Right 10%
export function RowR101(params) {
  const {
    isMobile,
    image,
    title,
    content,
    img,
    social,
    color,
    expand,
    setOk,
    setModalDetail,
  } = params;
  const imageSize = isMobile ? RowStyle.bannerI150 : RowStyle.bannerI500;
  const banneImage = {
    backgroundImage: `url(${image})`,
    ...imageSize.style,
  };

  const style = !isMobile ? PageStyle.maringR00010PR : null;
  const iscolor = !isMobile
    ? {
      style: {
        ...PageStyle.padding20P24P20P0P.style,
        backgroundColor: color ? color : PageValidation.color0,
      },
    }
    : { ...PageStyle.padding5 };

  return (
    <Row {...PageClasses.justifyStart} >

        <div >
          <Paragraph20RR
            value={title.content}
            color={title.color}
            align={title.align}
          ></Paragraph20RR>
          <br></br>
          <ScrollOverPack {...PageAnimation.scrollAnim.anim}>
            <TweenOne key="RowR10" {...PageAnimation.scrollAnim.anim2}>
              <PageDetail096RSMBS
                value={content}
                color={PageValidation.color1}
                align={PageValidation.justify}
                expand={expand}
              ></PageDetail096RSMBS>
            </TweenOne>

          </ScrollOverPack>
        </div>
    </Row>
  );
}

//Row center 3
export function RowC3(params) {
  const { colvalue, element ,handleRedirect, handleCurrent} = params;
  const span = CarouselStyle.span;
  let history = useHistory();


  const gonav = (item) => {
    handleCurrent(whiteSpace(`${MenuSource.SERVICED}`));
    handleRedirect(history, `${RouteUrl.SERVICIO2}/` + item.id);

  }
  return (
    <div {...CarouselClasses.block}>
      <Row {...PageClasses.justifyCenter} gutter={[8, 16]} style={{ borderRadius: "10px", borderColor: "red" }}>
        {element &&
          element.map((item, index) => (
            <Col span={3} {...colvalue} key={index}>
              <div {...PageClasses.blockContent} {...PageStyle.padding1}>
                <span
                  style={{
                    backgroundImage: `url(${item.data().src})`,
                    ...span.style,
                  }}
                ></span>
                <Paragraph12RR
                  value={item.data()?.nombre}
                ></Paragraph12RR>
                <br></br>
                <Paragraph096RL
                  value={item?.data().descripcionCorta}
                ></Paragraph096RL>
                <ButtonActionModal onclick={() => gonav(item)} ></ButtonActionModal>

              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
}

//Row Multiple
export function RowArray(params) {
  const { element, style } = params;
  const span = CarouselStyle.span;
  return (
    <div {...PageClasses.block}>
      <Tabs defaultActiveKey="0" centered>
        {element &&
          element.map((item, index) => (
            <TabPane key={index} tab={item.tab}>
              {item.source.map((elem, j) => (
                <div key={j}>
                  <div {...PageStyle.padding0}>
                    <Paragraph1RR
                      value={elem.title}
                      color={PageValidation.color1}
                    />
                    <hr></hr>
                  </div>
                  <Row {...PageClasses.justifyStart} gutter={[24, 24]}>
                    {elem.gallery.map((img, f) => (
                      <Col {...img.col} key={f}>
                        <div {...PageClasses.blockContent}>
                          <span
                            style={{
                              backgroundImage: `url(${img.img})`,
                              ...span.style,
                              ...style,
                            }}
                          ></span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
}
//Row Multiple
export function RowArrayByLocal(params) {
  const { element, isMobile, localidades } = params;
  const { img } = MissionSource;

  const style = {
    style: {
      backgroundImage: `url(${img})`,
      ...PageStyle.background2.style,
    },
  };
  const span = CarouselStyle.span;
  const goGoogleClick = (res) => {
    window.open('http://maps.google.com?daddr=' + res.Latitude + ',' + res.Longitude + '&amp;ll=', '_blank');

  }
  const goWhatsapp = (number, message) => {

    // Appending the phone number to the URL
    let url = `https://web.whatsapp.com/send?phone=${number}`;

    // Appending the message to the URL by encoding it
    url += `&text=${encodeURI(message)}&app_absent=0`;

    // Open our newly created URL in a new tab to send the message
    window.open(url);

  }
  const margi = !isMobile && PageStyle.margin1;

  return (
    <div {...PageClasses.block}>
      <Tabs defaultActiveKey="0" centered>
        {element &&
          element.map((item, index) => (
            <TabPane key={index} tab={item.tab}>
              <div {...PageClasses.page} {...style}>
                <div key={1} {...CarouselClasses.tweenOne} {...margi}>
                  {item.source.map((elem, j) => (
                    <div key={j}>
                      <div {...PageStyle.padding0}>
                        <Paragraph1RR
                          value={elem.title}
                          color={PageValidation.color21}
                        />
                        <hr></hr>
                      </div>
                      <Row {...PageClasses.justifyStart} gutter={[24, 24]}>
                        {localidades.map((item, f) => (
                          <Col key={f} >
                            <div {...PageClasses.blockContent} {...PageStyle.padding7} style={{ borderColor: "#DADADA", borderWidth: '10PX', padding: 10 }}>
                              <Paragraph144RR
                                value={item.Nombre}
                              ></Paragraph144RR>
                              <div {...PageStyle.padding0}>

                              <Row {...PageClasses.padding}>
                                  <div  >
                                    <AntDesignOutlined {...PageStyle.color22} />
                                  </div>
                                  <div  >
                                    <PageDetailCS084C
                                      value={item.Direccion}
                                      align={'justify'}
                                    />
                                  </div>
                                </Row>

                                <Row {...PageClasses.padding}>
                                  <div  >
                                    <PhoneOutlined {...PageStyle.color22} />
                                  </div>
                                  <div >
                                    <PageDetailCS084C
                                      value={item.Telefono}
                                      align={'justify'}
                                    />
                                  </div>
                                </Row>
                                <Row {...PageClasses.padding}>
                                  <div  >
                                    <MailOutlined {...PageStyle.color22} />
                                  </div>
                                  <div >
                                    <PageDetailCS084C
                                      value={item.Correo}
                                      align={'justify'}
                                    />
                                  </div>
                                </Row>

                              </div>

                              <div {...PageStyle.padding0}>
                                <PageDetailCS084C
                                  value={item.Horarios}
                                  align={'justify'}

                                ></PageDetailCS084C>
                              </div>

                              <div {...PageStyle.padding0}>

                                <ButtonAction
                                  item={item}
                                  onclick={() => goGoogleClick(item)}

                                ></ButtonAction>
                                <div  >
                                  <Button type="text" onClick={(e) => goWhatsapp(item.WhatsApp, "Hola, necesito información.")}>
                                    <WhatsAppOutlined style={{ fontSize: '20px', color: PageValidation.color10 }} />
                                    {item.WhatsApp}
                                  </Button>
                                </div>
                              </div>


                            </div>

                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
}
export function RowTextCard(params) {
  const { element } = params;
  return element.component ? (
    <Row gutter={[24, 24]} justify="center">
      {element.component.map((item, index) => (
        <Col key={index} {...item.col}>
          <Card
            headStyle={{
              backgroundImage: `url(${item.img})`,
              ...PageStyle.background3.style,
              ...PageStyle.border1.style,
              ...item?.style,
            }}
            bodyStyle={{ border: `solid 1px #d4d4d4` }}
            title={
              <Paragraph12RR
                value={item.title}
                color={PageValidation.color0}
                align={PageValidation.center}
              />
            }
            bordered={false}
          >
            <ListIconText item={item.list} classes={item?.class}></ListIconText>
          </Card>
        </Col>
      ))}
    </Row>
  ) : null;
}

//Row Span
export function RowSpan(params) {
  const { element, nextClick, setItemTab, setGallery } = params;
  const onClick = (value) => {
    setItemTab(RemoveBr(value.text));
    value.gallery && setGallery({ active: true, images: value.gallery });
    !value.gallery && nextClick();
  };

  return (
    <div {...PageClasses.block}>
      <Row {...element.justify} gutter={[24, 24]}>
        {element &&
          element.component.map((item, index) => (
            <Col {...item.col} key={index}>
              <div {...PageClasses.blockContent}>
                <span
                  style={{
                    backgroundImage: `url(${item?.img})`,
                    ...PageStyle.display1.style,
                    ...item?.style,
                  }}
                  onClick={() => onClick(item)}
                >
                  <div {...PageStyle.align2}>
                    {item.icon && (
                      <img src={item.icon} alt={PageValidation.alt} />
                    )}
                    {item.text && (
                      <div>
                        <Paragraph1RR
                          value={item.text}
                          color={PageValidation.color0}
                          align={PageValidation.start}
                        />
                      </div>
                    )}
                    {item?.logo && (
                      <img src={item.logo} alt={PageValidation.alt}></img>
                    )}
                  </div>
                </span>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export function RowOnlyText(params) {
  const { element, isMobile, backClick, gallery } = params;

  return element ? (
    <Row gutter={[24, 24]} {...PageClasses.justifyStart}>
      {element.map((item, index) => (
        <Col
          key={index}
          {...item.element.col}
          order={isMobile ? item.element.orderMobile : item.element.order}
        >
          {item.type === PageValidation.list && (
            <div {...PageStyle.padding0}>
              <ListIconText
                item={item.element.item}
                style={PageStyle.border2}
              ></ListIconText>
              <div {...PageStyle.absolute2}>
                <Button type="text" danger onClick={backClick}>
                  <LeftOutlined />
                  Atrás
                </Button>
              </div>
            </div>
          )}
          {item.type === PageValidation.carousel && item?.element?.item && (
            <ImageGallery
              style={PageStyle.background6.style}
              item={item?.element?.item}
              isMobile={isMobile}
            ></ImageGallery>
          )}
          {item.type === PageValidation.card && (
            <div>
              {item && <TitleText element={item}></TitleText>}
              {item?.type === PageValidation.card && (
                <RowTextCard element={item?.element}></RowTextCard>
              )}
            </div>
          )}
          {gallery?.nextback &&
            gallery?.nextback.map(
              (elem, index) =>
                gallery?.position == index && (
                  <div key={index} {...PageStyle.padding12}>
                    <TitleTextLine element={elem}></TitleTextLine>
                    <div {...PageStyle.padding12}>
                      <ImageGallery
                        style={PageStyle.background8.style}
                        item={elem?.img}
                        isMobile={isMobile}
                      ></ImageGallery>
                    </div>
                  </div>
                )
            )}
        </Col>
      ))}
    </Row>
  ) : null;
}
