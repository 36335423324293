import React, { useState, useEffect } from "react";
import { Row, Col, Form, Spin } from "antd";
import { JobFormSource } from "../../../../../source/component/home/jobs/JobSource";
import { PageStyle } from "../../../../../source/style/page/PageStyle";
import { Link } from 'react-router-dom';
import {
    PageForm,
    MessageSuccess,
    openNotificationWithIcon,
    ValidatingLoad,
    MessageServer
} from "../../../../../function/page/form/PageForm";
import { PageClasses } from "../../../../../source/classes/page/PageClasses";
import SocialForm from "./../../../../../function/form/SocialForm";
import {
    FormElementInput,
    FormElementTextArea,
} from "../../../../../function/form/ElementForm";
import { formValue } from "../../../../../source/form/ValueForm";
import emailjs from "@emailjs/browser";
import { whiteSpace } from "../../../../../script/caracter/CaracterScript";
import { MenuSource } from "../../../../../source/validation/menu/MenuValidation";
import { RowR101 } from "./../../../../../function/row/RowFunction";
import { PageValidation } from "../../../../../source/validation/page/PageValidation";
import { PageDetail096RSMBS } from '../../../../../function/paragraph/ParagraphFunction';

function JobForm(params) {
    const { isMobile, form, response, linkedin, Jbsimg1 } = params;
    const { formConstructor, submit } = JobFormSource;

    const [checked, setChecked] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [validator, setValidator] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const banneImage = {
        ...PageStyle.bannerIn900.style,
        ...PageStyle.background2.style,
        backgroundImage: `url(${Jbsimg1?.srcF})`,
    };
    const margi = !isMobile && PageStyle.margin1;

    const onFinish = (values) => {

        if(checked){
            setFormLoading(true);
            setIsLoading(ValidatingLoad);
            sendValue(values);
        }else{
            openNotificationWithIcon(MessageServer("Error", "Por favor, debes aceptar los políticas de privacidad"));
        }

    };

    function sendValue(_params) {
        _params.producto = Jbsimg1.nombre;
        emailjs
            .send("service_79tolrm", "template_nbeo3mf", _params, "gSYuPtH7g89lh9B7S")
            .then(
                (response) => {
                    setIsLoading(null);
                    form.setFields([
                        { name: [formValue.name], value: null },
                        { name: [formValue.lastname], value: null },
                        { name: [formValue.email], value: null },
                        { name: [formValue.document], value: null },
                        { name: [formValue.phone], value: null },
                        { name: [formValue.position], value: null },
                        { name: [formValue.obs], value: null },
                        {
                            name: [formValue.identification],
                            value: null,
                        },
                    ]);
                    setIsLoading(null);
                    setFormLoading(false);
                    form.resetFields();
                    openNotificationWithIcon(MessageSuccess);
                },
                (error) => { }
            );
    }
    const handleOnChange = () => {
        setChecked(true)

    }
    return (
        <div
            {...PageClasses.page}
            {...margi}
            id={`${whiteSpace(MenuSource.SERVICE)}`}
        >
            <div {...PageStyle.padding0}>
                <Row {...PageClasses.justifyEnd} {...PageStyle.row} gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={14} lg={14}>
                        <div {...PageStyle.marginR10}>
                            <RowR101
                                isMobile={isMobile}
                                title={{
                                    content: Jbsimg1?.nombre ? Jbsimg1?.nombre : "",
                                    color: PageValidation.colorprimary,
                                    align: PageValidation.left,
                                }}
                                content={
                                    Jbsimg1?.descripcionLarga ? Jbsimg1?.descripcionLarga : ""
                                }
                                color={PageValidation.color0}
                            ></RowR101>

                            <Spin spinning={formLoading} tip="Cargando....">
                                <Form
                                    form={form}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    scrollToFirstError
                                >
                                    <Row gutter={[8, 8]} justify={"center"}>
                                        {formConstructor.map((item, index) => (
                                            <Col key={index} {...item.col}>
                                                {item.element.type === PageForm.INPUT && (
                                                    <FormElementInput
                                                        isLoading={isLoading}
                                                        item={item}
                                                        validator={validator}
                                                        setValidator={setValidator}
                                                        form={form}
                                                    />
                                                )}
                                                {item.element.type === PageForm.TEXTAREA &&
                                                    document && (
                                                        <FormElementTextArea
                                                            isLoading={isLoading}
                                                            item={item}
                                                            validator={validator}
                                                            form={form}
                                                        />
                                                    )}
                                            </Col>
                                        ))}

                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Row >
                                                <Col >

                                                    <input
                                                        type="checkbox"
                                                        id={1}
                                                        className="react-cookie-law-option-checkbox"
                                                        checked={checked}
                                                        onChange={handleOnChange}
                                                    />
                                                </Col>
                                                <Col >
                                                    <a href={'/documento/politicas-privacidad'} style={{ textDecoration: 'underline'}}>
                                                        <PageDetail096RSMBS
                                                            value={"He leído y acepto la políticas de privacidad"}
                                                            color={PageValidation.color4}
                                                            align={PageValidation.center}
                                                            
                                                        ></PageDetail096RSMBS>
                                                    </a>
                                                </Col>

                                            </Row>
                                            {response && (
                                                <SocialForm
                                                    form={form}
                                                    submit={submit}
                                                    message={response}
                                                    linkedin={linkedin}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </div>
                    </Col>
                    <Col md={10} lg={10}>
                        <div style={banneImage}></div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default JobForm;
