import React from 'react';
import { Modal, Button, Avatar, Row, Col } from 'antd';
import { PageImage } from '../../source/image/PageImage';
import {
  PageDetail096RSMBS,
  Paragraph144RR,
} from '../paragraph/ParagraphFunction';
import { PageStyle } from '../../source/style/page/PageStyle';
import { PageValidation } from '../../source/validation/page/PageValidation';

function ModalDetail(params) {
  const { isMobile, content, color, ok, setOk, image, title, align } = params;
  const { general } = PageImage;
  function onOk(value) {
    setOk(value);
  }

  return (
    <Modal
      visible={ok}
      onOk={() => onOk(false)}
      onCancel={() => onOk(false)}
      closable={false}
      width={isMobile ? PageValidation.width90 : PageValidation.width77}
      footer={null}
      centered
      style={{ top: 35 }}
    >
      <div {...PageStyle.absolute1}>
        <Button
          {...PageStyle.padding8}
          type={PageValidation.link}
          onClick={() => onOk(false)}
        >
          <img src={general.Gnrimg3} alt={PageValidation.alt}></img>
        </Button>
      </div>

      {content && (
        <Row gutter={[24]}>
          {!isMobile && (
            <Col md={5} lg={3}>
              {image && (
                <Avatar
                  style={{ width: 100, height: 100 }}
                  src={image}
                  alt={PageValidation.alt}
                />
              )}
            </Col>
          )}
          <Col
            xs={24}
            sm={24}
            md={{ span: 18, offset: 1 }}
            lg={{ span: 20, offset: 1 }}
          >
            <Paragraph144RR
              value={title}
              align={align}
              color={color}
            ></Paragraph144RR>
            <PageDetail096RSMBS
              value={content}
              color={color}
              align={align}
            ></PageDetail096RSMBS>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default ModalDetail;
