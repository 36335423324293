import React from 'react';
import { PageStyle } from './../../source/style/page/PageStyle';
import parse from 'html-react-parser';
import { Typography } from 'antd';

const { Paragraph } = Typography;

//Page detail fontsize 0.99rem font family Roboto Regular withOut Margin Bottom Start
export function PageDetail096RSMBS(params) {
  const { value, color, align, expand } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS096.style };
  const ellipsis = expand && expand;
  return (
    <Paragraph {...ellipsis} style={style}>
      {parse(value)}
    </Paragraph>
  );
}

//Page detail fontsize 0.99rem font family Roboto light withOut Margin Bottom Start
export function Paragraph096RL(params) {
  const { value, color, align, expand } = params;
  const style = { color, textAlign: align, ...PageStyle.LSMBS096.style };
  const ellipsis = expand && expand;
  return (
    <Paragraph {...ellipsis} style={style}>
      {parse(value)}
    </Paragraph>
  );
}

//Page detail fontsize 0.84rem font family Roboto Regular withOut Margin Bottom Start
export function PageDetailCS084C(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS084.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 2.2rem font family Roboto Medium withOut Margin Bottom Start
export function PageDetailCS022C(params) {
  const { value, color } = params;
  const style = PageStyle.RSMBS22.style;
  style.color = color;
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 2.2rem font family Roboto Light withOut Margin Bottom Start
export function Paragraph22RL(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RLSMBS22.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 2.0rem font family Roboto Regular withOut Margin Bottom Start
export function Paragraph20RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS20.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 2.0rem font family Roboto Light withOut Margin Bottom Start
export function Paragraph20RL(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RLSMBS20.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 2.0rem font family Roboto Light withOut Margin Bottom Start
export function Paragraph17RL(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RLSMBS17.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 1.44rem font family Roboto Regular
export function Paragraph144RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS144.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 1.2rem font family Roboto Regular
export function Paragraph12RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS12.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 1.2rem font family Roboto Regular
export function Paragraph11RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS11.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 1rem font family Roboto Regular
export function Paragraph1RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBS1.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}

//Page detail fontsize 3.125rem font family Roboto Regular
export function Paragraph3125RR(params) {
  const { value, color, align } = params;
  const style = { color, textAlign: align, ...PageStyle.RSMBC144.style };
  return <Paragraph style={style}>{parse(value)}</Paragraph>;
}
