import React from 'react';
import { PageImage } from './../../image/PageImage';
import { PageValidation } from './../../validation/page/PageValidation';
import { MenuMultiple } from './MenuMultiple';

export const MenuItemSource = {
  col0: { xs: 24, sm: 8, md: 6, lg: 6 },
  col1: { xs: 24, sm: 14, md: 9, lg: 9 },
  menu: MenuMultiple.home,
  social: {
    title: 'Síguenos en:',
    list: [
      {
        id: 0,
        name: 'twitter',
        link: 'https://twitter.com/Austrofinancial',
        icon: PageImage.social.Lkimg1,
      },
      {
        id: 1,
        name: 'youtube',
        link: 'https://www.youtube.com/channel/UCzR8WPEmeLdsemUCF98mz7Q',
        icon: PageImage.social.Lkimg2,
      },
    ],
  },
  politicas: {
    title: 'Políticas de empresa',
    list: [
      {
        id: 0,
        name: 'aviso-legal',
        text:'Aviso legal',
        url:'/documento/aviso-legal'
      },
      {
        id: 1,
        name: 'politicas-cookies',
        text:'Políticas cookies',
        url:'/documento/politicas-cookies'

      },
      {
        id: 2,
        name: 'politicas-privacidad',
        text:'Póliticas privacidad',
        url:'/documento/politicas-privacidad'

      },
    ],
  },
  information: {
    title: `Mantengámonos <br> en contacto.`,
    content: `Esperamos que te hayan gustado nuestros productos y servicios, si quieres contactarte para más información o si tienes alguna pregunta o sugerencia, no dudes en escribirnos.<br>`,
    data: [
      [
        {
          id: 0,
          img: <img src={PageImage.general.Gnrimg1} alt="AFS" />,
          alt: 'AFS',
          text: `CALLE PASEO MARQUEZ DE CORVERA, 44 CP 30002`,
        },
      ],
      [
        {
          id: 0,
          img: <img src={PageImage.general.Gnrimg0} alt="AFS" />,
          alt: 'AFS',
          text: `<a class='page-a' href='tel:+34968291329'>(+34) 968291329</a>`,
        },
      ],
      [
        {
          id: 0,
          img: <img src={PageImage.general.Gnrimg2} alt="AFS" />,
          alt: 'AFS',
          text: PageValidation.email,
        },
      ],
    ],
  },
};
