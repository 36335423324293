export const formValue = {
  interest: 'interest',
  position: 'position',
  document: 'document',
  name: 'name',
  lastname: 'lastname',
  identification: 'identification',
  phone: 'phone',
  email: 'email',
  file: 'file',
  caption: 'caption',
  profile: 'profile',
  obs: 'observacion'
};
