import React from 'react';
import { ErrorLoads, ValidatorDocument } from '../page/form/PageForm';
import { formValue } from './../../source/form/ValueForm';
import { onlyNumber } from './../../constant/isnumeric/IsNumeric';
import { Form, Input, Radio, Select, Typography, Row, Col, Upload } from 'antd';
import { ValidatingLoad } from './../page/form/PageForm';
import {
  asyncIdentification,
  asyncRuc,
} from './../../constant/isidentification/IsIdentification';
import { Paragraph096RL } from '../paragraph/ParagraphFunction';
import { InboxOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Paragraph, Text } = Typography;

export function FormElementInput(params) {
  const { item, isLoading, validator } = params;

  const validateRule = (rule, value) => {
    let validate = true;
    switch (item.name) {
      case formValue.identification:
        validate =
          validator === ValidatorDocument.IDENTIFICATION
            ? asyncIdentification(value)
            : validator === ValidatorDocument.RUC
            ? asyncRuc(value)
            : true;
        break;
      default:
        validate = true;
        break;
    }
    return validate ? Promise.resolve() : Promise.reject(item.message.rule);
  };

  const onKeyPress = () => (e) => {
    const isPassport = validator === ValidatorDocument.PASSPORT;
    return item.name === formValue.phone
      ? onlyNumber(e)
      : item.name === formValue.identification && !isPassport && onlyNumber(e);
  };
  return (
    <Form.Item
      {...isLoading}
      name={item.name}
      validateTrigger={['onBlur']}
      rules={[item.rule, { validator: validateRule }]}
    >
      <Input
        {...item.style}
        onKeyPress={onKeyPress()}
        placeholder={item.element.placeholder}
      />
    </Form.Item>
  );
}

export function FormElementSelect(params) {
  const { item, data, isLoading, form, setValidator } = params;

  function handleChange(value, event) {
    form.setFields([
      { name: [`${formValue.identification}`], value: null, errors: [''] },
    ]);
    setValidator(event.children);
  }
  const option =
    data && data.filter((response) => response.name === item.name)[0].element;
  const load = !data && ValidatingLoad;
  return (
    <Form.Item {...isLoading} {...load} name={item.name} rules={[item.rule]}>
      <Select onChange={handleChange} placeholder={item.element.placeholder}>
        {option &&
          option.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.dt1}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export function FormElementTextArea(params) {
  const { item, isLoading } = params;
  return (
    <Form.Item {...isLoading} name={item.name} rules={[item.rule]}>
      <TextArea
        placeholder={item.element.placeholder}
        rows={item.element.rows}
      />
    </Form.Item>
  );
}

export function FormElementRadio(params) {
  const {
    item,
    isLoading,
    title,
    setCategoryId,
    setLoadNotice,
    loadNotice,
  } = params;
  const isLoad = isLoading && isLoading;
  const colmedium = { xs: 24, sm: 12, md: 6, lg: 6 };
  const onChange = (e) => {
    setCategoryId(e.target.value);
    setLoadNotice(true);
  };
  return (
    <div>
      <Paragraph096RL value={title}></Paragraph096RL>
      <Form.Item {...isLoad} name={item.name} rules={[item.rule]}>
        <Radio.Group
          onChange={onChange}
          style={{ width: '100%' }}
        >
          <Row gutter={[8, 8]}>
            {item.map((element, index) => (
              <Col key={index} {...colmedium}>
                <Radio value={element.id}>{element.dt1}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}

export function FormElementUpLoad(params) {
  const {
    item,
    formLoading,
    fileList,
    beforeUpload,
    onRemove,
    fileOnChange,
  } = params;
  const isFile = !fileList && ErrorLoads;
  const aux = (a) =>{
    try{
      if(a.dataTransfer.files[0].type!=='application/pdf'){
        fileOnChange({file:a.dataTransfer.files[0]})
      }
    } catch (e) {
     console.error(e)
    }
  }
  return (
    <Form.Item
      {...isFile}
      valuePropName={item.name}
      name={item.name}
      rules={[item.rule]}
    >
      <Upload.Dragger
        onChange={fileOnChange}
        onDrop={aux}
        fileList={fileList}
        accept={item.element.only}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        <Paragraph>
          Arrastre el archivo{' '}
          <Text type="danger">
            <strong>PDF</strong>
          </Text>{' '}
          o suba uno nuevo
        </Paragraph>
      </Upload.Dragger>
    </Form.Item>
  );
}
