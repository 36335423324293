import { PageValidation } from './../../../validation/page/PageValidation';
import { PageImage } from './../../../image/PageImage';
export const OurServiceSource = {
  col: { xs: 24, sm: 12, md: 12, lg: 8 },
  title: {
    content: `<br><br>Nuestros Servicios`,
    color: PageValidation.colorprimary,
    align: PageValidation.center,
  },
  data: [
    {
      id: 0,
      title: {
        content: `Tecnología`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Software desarrollado con tecnología de última generación, que dinamiza a las empresas para que su gestión diaria sea efectiva con calidad y alto rendimiento.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg0, style: { height: 45 } },
    },
    {
      id: 0,
      title: {
        content: `Tecnología`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Software desarrollado con tecnología de última generación, que dinamiza a las empresas para que su gestión diaria sea efectiva con calidad y alto rendimiento.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg0, style: { height: 45 } },
    },
    {
      id: 0,
      title: {
        content: `Tecnología`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Software desarrollado con tecnología de última generación, que dinamiza a las empresas para que su gestión diaria sea efectiva con calidad y alto rendimiento.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg0, style: { height: 45 } },
    },
    {
      id: 0,
      title: {
        content: `Tecnología`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Software desarrollado con tecnología de última generación, que dinamiza a las empresas para que su gestión diaria sea efectiva con calidad y alto rendimiento.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg0, style: { height: 45 } },
    },
    {
      id: 1,
      title: {
        content: `Soporte Técnico`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Somos un socio estratégico, que apoya a las empresas a satisfacer sus requerimientos en forma directa, o a través de herramientas que permiten dar apoyo remoto, de tal forma que resuelvan sus necesidades de operación  del sistema en forma oportuna.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg1, style: { height: 45 } },
    },
    {
      id: 2,
      title: {
        content: `Inteligencia de negocio`,
        color: PageValidation.color2,
        align: PageValidation.center,
      },
      content: {
        content: `Utilizamos herramientas de análisis de información, como generador de valor a las diferentes áreas de la empresa al menor costo posible.`,
        color: PageValidation.color1,
        align: PageValidation.justify,
      },
      svg: { img: PageImage.service.Serimg2, style: { height: 45 } },
    },
  ],
};
