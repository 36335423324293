import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import ScrollAnim from 'rc-scroll-anim';
import { whiteSpace } from './../../../script/caracter/CaracterScript';
import HomeBanner from './banner/HomeBanner';
import AboutUs from './aboutus/AboutUs';
import Mission from './mission/Mission';
import OurService from './ourservice/OurService';
import Client from './client/Client';
import { MenuSource } from './../../../source/validation/menu/MenuValidation';
import { MenuMultiple } from './../../../source/component/menu/MenuMultiple';

function Home(params) {
  const {
    isMobile,
    current,
    setItemMenu,
    itemMenu,
    handleRedirect,
    history,
  } = params;
  const Links = ScrollAnim.Link;
  const scrollTo = current && itemMenu?.filter((s) => s.name === current)[0];
  const event = useRef(null);
  const children = [
    <Links
      key={0}
      to={`${scrollTo ? scrollTo.name : whiteSpace(MenuSource.HOME)}`}
      offsetTop={0}
      duration={1000}
    >
      <div ref={event}></div>
    </Links>,
    <HomeBanner key={1} isMobile={isMobile} ></HomeBanner>,
    <AboutUs key={2} isMobile={isMobile} ></AboutUs>,
    <Mission key={4} isMobile={isMobile} ></Mission>,
    <OurService
      key={3} isMobile={isMobile}
      handleRedirect={handleRedirect}
      history={history}
    ></OurService>,
    <Client key={5} isMobile={isMobile} ></Client>,
 

  ];

  useEffect(() => {
    event.current.click();
    setItemMenu(MenuMultiple.home);
  }, [setItemMenu]);

  return <div>{children}</div>;
}

const mapStateToProps = (state) => ({
  current: state.current,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
