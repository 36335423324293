import { PageValidation } from './../../validation/page/PageValidation';

export const PageStyle = {
  maringR00010PR: { style: { margin: '0 24px 0 0' } },
  padding20P24P20P0P: {
    style: {
      padding: `0px ${PageValidation.var8}px 0px 0`,
    },
  },
  row: { style: { alignItems: PageValidation.center } },
  bannerStatic: { style: { zIndex: PageValidation.zIndex0 } },
  menuStatic: {
    style: {
      position: PageValidation.positionAbs,
      zIndex: PageValidation.zIndex1,
      width: PageValidation.width1,
    },
  },
  menuIcon: {
    style: {
      paddingLeft: PageValidation.unset,
      paddingRight: PageValidation.unset,
    },
  },
  RSMBS096: {
    style: {
      fontSize: PageValidation.fontSize1,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  LSMBS096: {
    style: {
      fontSize: PageValidation.fontSize1,
      fontFamily: PageValidation.fontFamily3,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS084: {
    style: {
      fontSize: PageValidation.fontSize2,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS22: {
    style: {
      fontSize: PageValidation.fontSize3,
      fontFamily: PageValidation.fontFamily2,
      marginBottom: PageValidation.unset,
    },
  },
  RLSMBS22: {
    style: {
      fontSize: PageValidation.fontSize3,
      fontFamily: PageValidation.fontFamily3,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS20: {
    style: {
      fontSize: PageValidation.fontSize4,
      fontFamily: PageValidation.fontFamily2,
      marginBottom: PageValidation.unset,
    },
  },
  RLSMBS20: {
    style: {
      fontSize: PageValidation.fontSize4,
      fontFamily: PageValidation.fontFamily3,
      marginBottom: PageValidation.unset,
    },
  },
  RLSMBS17: {
    style: {
      fontSize: PageValidation.fontSize8,
      fontFamily: PageValidation.fontFamily3,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS144: {
    style: {
      fontSize: PageValidation.fontSize5,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS12: {
    style: {
      fontSize: PageValidation.fontSize9,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS11: {
    style: {
      fontSize: PageValidation.fontSize10,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBS1: {
    style: {
      fontSize: PageValidation.fontSize7,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RSMBC144: {
    style: {
      fontSize: PageValidation.fontSize6,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  RR144: {
    style: {
      fontSize: PageValidation.fontSize5,
      fontFamily: PageValidation.fontFamily1,
      marginBottom: PageValidation.unset,
    },
  },
  padding4p10p: {
    style: { padding: `${PageValidation.var1}px ${PageValidation.var2}px` },
  },
  background0: { style: { backgroundColor: PageValidation.color0 } },
  background1: { style: { backgroundColor: PageValidation.color2 } },
  background2: {
    style: {
      backgroundPosition: PageValidation.center,
      backgroundSize: PageValidation.cover,
      backgroundRepeat: PageValidation.norepeat,
    },
  },
  background3: {
    style: {
      backgroundPosition: PageValidation.center,
      backgroundSize: PageValidation.cover,
      backgroundRepeat: PageValidation.norepeat,
    },
  },
  background4: {
    style: {
      backgroundPosition: `20% 0px`,
      backgroundSize: PageValidation.cover,
      backgroundRepeat: PageValidation.norepeat,
    },
  },
  background5: {
    style: {
      backgroundPosition: `0px 60px`,
      backgroundSize: PageValidation.cover,
      backgroundRepeat: PageValidation.norepeat,
    },
  },
  background6: {
    style: {
      backgroundPosition: PageValidation.center,
      backgroundSize: PageValidation.contain,
      backgroundRepeat: PageValidation.norepeat,
      backgroundColor: PageValidation.color11,
    },
  },
  background7: {
    style: {
      backgroundPosition: PageValidation.center,
      backgroundSize: `${PageValidation.var20}%`,
      backgroundRepeat: PageValidation.norepeat,
      backgroundColor: PageValidation.color11,
    },
  },
  background8: {
    style: {
      backgroundPosition: PageValidation.center,
      backgroundSize: PageValidation.contain,
      backgroundRepeat: PageValidation.norepeat,
    },
  },
  paddingLeft22: {
    style: { paddingLeft: `${PageValidation.var6}px` },
  },
  marginL10: {
    style: { marginLeft: `${PageValidation.var2}%` },
  },
  margin1: { style: { margin: `0 50px` } },
  margin2: { style: { margin: `30px` } },
  margin3: { style: { margin: '24px 16px 0', overflow: 'initial' } },
  margin4: { style: { margin: '24px 16px 0px 219px', overflow: 'initial' } },
  padding7: { style: { padding: `10px`, minHeight: PageValidation.var9 } },
  padding0: { style: { padding: `20px 0px` } },
  padding1: { style: { padding: `30px`, minHeight: PageValidation.var10 } },
  padding2: { style: { padding: `20px` } },
  padding3: { style: { padding: `30px` } },
  padding4: { style: { padding: `60px 24px` } },
  padding5: { style: { padding: `unset` } },
  padding6: { style: { padding: `10px 0` } },
  padding8: { style: { padding: `unset` } },
  padding9: { style: { padding: `0 24px` } },
  padding10: { style: { padding: `40px 0px` } },
  padding11: { style: { padding: `20px 60px` } },
  padding12: { style: { paddingTop: `20px` } },
  align1: { style: { textAlign: PageValidation.center } },
  align2: { style: { textAlign: PageValidation.start } },
  anim1: { style: { opacity: 0, transform: PageValidation.translate0 } },
  bannerIn900: {
    style: {
      minHeight: 900,
    },
  },
  absolute1: {
    style: { position: PageValidation.positionAbs, left: '100%', top: '-28px' },
  },
  absolute2: {
    style: { position: PageValidation.positionAbs, bottom: 0 },
  },
  height1: { style: { minHeight: `${PageValidation.var11}vh` } },
  height2: { style: { height: `${PageValidation.var9}px` } },
  height3: { style: { height: `${PageValidation.var18}px` } },
  height4: {
    style: {
      height: `${PageValidation.var11}%`,
      backgroundColor: PageValidation.color0,
    },
  },
  height5: { style: { height: `inherit` } },
  line1: {
    style: { width: `100%`, height: `1.5px` },
  },
  border1: { style: { borderRadius: `7px 7px 0 0` } },
  border2: { style: { border: PageValidation.unset } },
  sider1: {
    style: {
      height: '100vh',
      position: 'fixed',
      left: 0,
      zIndex: 1,
      backgroundColor: PageValidation.color21,
    },
  },
  color1: { style: { color: PageValidation.color10 } },
  color22: { style: { color: PageValidation.color22 } },

  display1: {
    style: {
      justifyContent: PageValidation.start,
      alignItems: PageValidation.center,
      display: PageValidation.flex,
    },
  },
  display2: {
    style: {
      justifyContent: PageValidation.center,
      alignItems: PageValidation.center,
      display: PageValidation.flex,
      height: `${PageValidation.var11}%`,
    },
  },
  width0: {
    style: { width: PageValidation.var19, textAlign: PageValidation.end },
  },
};
