import React from 'react';
import CookieOption from './CookieOption';
import bannerStyle from './bannerStyle';
import QueueAnim from 'rc-queue-anim';
import { PageClasses } from './../../../source/classes/page/PageClasses';
import { PageStyle } from './../../../source/style/page/PageStyle';
import { Button } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props = {}) => {
  const {
    styles = {},
    className = '',
    message = 'No text',
    policyLink = '/documento/politicas-cookies',
    privacyPolicyLinkText = 'Políticas de cookies',
    necessaryOptionText = 'necesario',
    preferencesOptionText = 'Preferencias',
    statisticsOptionText = 'Estadísticas',
    marketingOptionText = 'Marketing',
    showAcceptSelectionButton = false,
    acceptAllButtonText = 'Aceptar',
    calcelAllButtonText = 'Rechazar',
    acceptSelectionButtonText = 'Accept Selection',
    showPreferencesOption = true,
    showStatisticsOption = true,
    showMarketingOption = true,
    showEdit = false,
    preferencesOptionInitiallyChecked = false,
    statisticsOptionInitiallyChecked = false,
    marketingOptionInitiallyChecked = false,
    onTogglePreferencesCookies = Function,
    onToggleStatisticsCookies = Function,
    onToggleMarketingCookies = Function,
    onConfirmSelection = Function,
    onConfirmAll = Function,
    openClav=Function
  } = props;
  const {
    dialog: dialogStyle,
    container: containerStyle,
    message: messageStyle,
    policy: policyStyle,
    selectPane: selectPaneStyle,
    optionWrapper: optionWrapperStyle,
    optionLabel: optionLabelStyle,
    checkbox: checkboxStyle,
    buttonWrapper: buttonWrapperStyle,
    button: buttonStyle,
  } = { ...bannerStyle, ...styles };

  const cookieOptionStyle = { optionWrapperStyle, optionLabelStyle, checkboxStyle };
  if (showEdit) {
    return (
      <div style={{position: 'fixed', bottom: 0, right: 0,zIndex:10}}>
        <QueueAnim name="QueueAnim">
          <div {...PageClasses.menu} {...PageStyle.margin1}>
            <div {...PageStyle.paddingLeft22}>
              <Button type="text" onClick={openClav}>
                <ControlOutlined style={{ fontSize: '40px' }} />
              </Button>
            </div>
          </div>
        </QueueAnim>      </div>
    )
  } else {
    return (
      <div className={`react-cookie-law-dialog ${className}`} style={dialogStyle}>

        <div className="react-cookie-law-container" style={containerStyle}>
          <div className="react-cookie-law-msg" style={messageStyle}>{message}</div>


          <div className="react-cookie-law-select-pane" style={selectPaneStyle}>
            <CookieOption
              id="check-required-cookies"
              text={necessaryOptionText}
              styles={cookieOptionStyle}
              disabled
              checked
            />

            {
              showPreferencesOption && (
                <CookieOption
                  id="check-preferences-cookies"
                  text={preferencesOptionText}
                  styles={cookieOptionStyle}
                  onChange={onTogglePreferencesCookies}
                  checked={preferencesOptionInitiallyChecked}
                />
              )
            }

            {
              showStatisticsOption && (
                <CookieOption
                  id="check-statistics-cookies"
                  text={statisticsOptionText}
                  styles={cookieOptionStyle}
                  onChange={onToggleStatisticsCookies}
                  checked={statisticsOptionInitiallyChecked}
                />
              )
            }

            {
              showMarketingOption && (
                <CookieOption
                  id="check-marketing-cookies"
                  text={marketingOptionText}
                  styles={cookieOptionStyle}
                  onChange={onToggleMarketingCookies}
                  checked={marketingOptionInitiallyChecked}
                />
              )
            }
          </div>

          <a href={policyLink} className="react-cookie-law-policy" style={policyStyle}>{privacyPolicyLinkText}</a>

          <div className="react-cookie-law-button-wrapper" style={buttonWrapperStyle}>
            {
              showAcceptSelectionButton && (
                <button type="button" className="react-cookie-law-accept-selection-btn" style={buttonStyle} onClick={() => onConfirmSelection()}>
                  <span>{acceptSelectionButtonText}</span>
                </button>
              )
            }

            <button type="button" className="react-cookie-law-accept-all-btn" style={buttonStyle} onClick={() => onConfirmAll(true)}>
              <span>{acceptAllButtonText}</span>
            </button>
            <button type="button" className="react-cookie-law-accept-all-btn" style={buttonStyle} onClick={() => onConfirmAll(false)}>
              <span>{calcelAllButtonText}</span>
            </button>

          </div>
        </div>
      </div>
    );
  }

};