
export const LoadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {
        current: null,
        token: '',
        scroll: null,
        response: null,
      };
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const SaveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
};
