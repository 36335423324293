import { PageImage } from './../../../image/PageImage';
export const HomeBannerSource = {
  title: '',
  content: ``,
  gallery: [
    {
      id: 0,
      img: PageImage.homeGallery.Glyimg3,
      title: `Estamos en constante <br /> mejoramiento`,
    },
    {
      id: 1,
      img: PageImage.homeGallery.Glyimg0,
      title: `Innovación <br /> tecnológica`,
    },
    {
      id: 2,
      img: PageImage.homeGallery.Glyimg1,
      title: 'Hacemos realidad <br /> sus proyectos de <br /> automatización',
    },
    {
      id: 3,
      img: PageImage.homeGallery.Glyimg2,
      title: 'Socio estratégico <br /> en tecnología',
    },
  ],
};
