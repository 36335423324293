import { PageValidation } from './../../../validation/page/PageValidation';
export const AboutSource = {
  title: {
    content: `Sobre nosotros`,
    color: PageValidation.colorprimary,
    align: PageValidation.left,
  },
  content: `Somos una empresa con presencia en España y Estados Unidos constituidas independientemente y dedicadas a promover entre la comunidad latinoamericana productos y servicios de empresas comerciales o financieras de gran solvencia y trayectoria establecidas en Ecuador y otros países de Latinoamérica, implementando herramientas estratégicas adecuadas según las necesidades de nuestros clientes, desarrollando actividades debidamente planifcadas con objetivos claros y medibles mediante el análisis previo de la situación. Orientamos a los empresarios hacia el manejo acertado de marca para llegar a su mercado objetivo en los Estados Unidos y España.Nos especializamos en la planeación y diseño de eventos y campañas publicitarias para empresas reconocidas en Ecuador y Latinoamérica. Damos respuesta a las necesidades de nuestros usuarios y colaboradores de forma oportuna, amable y efectiva. generamos satisfacción a nuestros usuarios cuando agregamos valor a nuestro trabajo.Hacemos uso adecuado y óptimo de los recursos. comunicamos de forma veraz y completa las actuaciones de la empresa. construimos confianza através de relaciones claras y abiertas.  `,
  //img:"https://firebasestorage.googleapis.com/v0/b/afsespana.appspot.com/o/AFS%2F1.jpg?alt=media&token=f766b4f6-6660-441c-9234-8dc16fd95772",
  img:"https://firebasestorage.googleapis.com/v0/b/afsespana.appspot.com/o/AFS%2F2.jpg?alt=media&token=42da439e-5d3b-49c0-898c-d889974abfa6",

  expand: { ellipsis: { rows: 7, expandable: false } },
};
