import { PageValidation } from './../../../validation/page/PageValidation';
import { PageImage } from './../../../image/PageImage';
export const MissionSource = {
  img: PageImage.mission.VideoMision,
  data: [

    {
      id: 1,
      title: {
        content: `Misión`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Contribuir a mejorar la vida de familias radicadas en España, ayudándoles adquirir y gestionar su patrimonio en sus países de origen mediante un servicio global y personalizado que les facilite alcanzar sus objetivos.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg1,
      video: PageImage.mission.VideoMision,
    },
    {
      id: 2,
      title: {
        content: `Visión`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Ser una empresa de promoción e intermediación comercial que mediante un servicio de calidad fidelice y satisfaga a los migrantes radicados en España.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
    {
      id: 3,
      title: {
        content: `Servicio al cliente`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Damos respuesta a las necesidades de nuestros usuarios y colaboradores de forma oportuna, amable y efectiva. generamos satisfacción a nuestros usuarios cuando agregamos valor a nuestro trabajo.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
    {
      id: 4,
      title: {
        content: `Transparencia`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Hacemos uso adecuado y óptimo de los recursos. comunicamos de forma veraz y completa las actuaciones de la empresa. construimos confianza através de relaciones claras y abiertas.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
    {
      id: 5,
      title: {
        content: `Respeto`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Hacemos uso adecuado y óptimo de los recursos. comunicamos de forma veraz y completa las actuaciones de la empresa. construimos confianza através de relaciones claras y abiertas.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
    {
      id: 6,
      title: {
        content: `Excelencia`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Hacemos uso adecuado y óptimo de los recursos. comunicamos de forma veraz y completa las actuaciones de la empresa. construimos confianza através de relaciones claras y abiertas.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
    {
      id: 7,
      title: {
        content: `Responsabilidad`,
        color: PageValidation.colorprimary,
        align: PageValidation.center,
      },
      content: {
        content: `Hacemos uso adecuado y óptimo de los recursos. comunicamos de forma veraz y completa las actuaciones de la empresa. construimos confianza através de relaciones claras y abiertas.`,
        color: PageValidation.color1,
        align: PageValidation.center,
      },
      expand: { ellipsis: { rows: 4, expandable: false } },
      img: PageImage.mission.Misimg3,
    },
  ],
};
