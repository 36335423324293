import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Spin } from 'antd';
import { JobFormSource } from '../../../../../source/component/home/jobs/JobSource';
import { PageStyle } from '../../../../../source/style/page/PageStyle';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
    PageForm,
    MessageSuccess,
    openNotificationWithIcon,
    ValidatingLoad
} from '../../../../../function/page/form/PageForm';
import { PageClasses } from '../../../../../source/classes/page/PageClasses';
import SocialForm from './../../../../../function/form/SocialForm';
import {
    FormElementInput,
    FormElementTextArea
} from '../../../../../function/form/ElementForm';
import { formValue } from '../../../../../source/form/ValueForm';
import emailjs from '@emailjs/browser';
import { whiteSpace } from '../../../../../script/caracter/CaracterScript';
import { MenuSource } from '../../../../../source/validation/menu/MenuValidation';
import { Paragraph20RR } from '../../../../../function/paragraph/ParagraphFunction';


function JobForm(params) {
    const {
        isMobile,
        form,
        response,
        linkedin,
        Jbsimg1
    } = params;
    const {
        formConstructor,
        submit,
    } = JobFormSource;
    const margi = !isMobile && PageStyle.margin1;


    return (
        <div
            {...PageClasses.page}
            {...margi}
            id={`${whiteSpace(MenuSource.SERVICE)}`}
        >
            {Jbsimg1 && <div {...PageStyle.padding0}>
                <Paragraph20RR
                    value={Jbsimg1?.Nombre}
                ></Paragraph20RR>
            </div>}
            {Jbsimg1 && <div dangerouslySetInnerHTML={{ __html: "<iframe src=" + Jbsimg1?.Url + " style='width:100%;height:600px' />" }} />}

        </div>
    );
}

export default JobForm;
