import { notification } from 'antd';

export const PageForm = {
  INPUT: 'INPUT',
  TEXTAREA: 'TEXTAREA',
  SELECT: 'SELECT',
  UPLOAD: 'UPLOAD',
  RADIO: 'RADIO',
  CAPTION: 'CAPTION',
  PROFILE: 'PROFILE',
  LINE: 'LINE',
  REQUIRED: { required: true, message: 'Es requerido' },
  NOTREQUIRED: { required: false },
  PHONE: { required: true, message: 'Requerido máximo 10 caracteres' },
  OBS: { required: false, message: 'Se acepta máximo 250 letras', max: 250 },
  EMAIL: { required: true, message: 'Email inválido' },
};

export const MensajeVariante = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const UploadError = {
  isPdf: 'Extensión inválida, permitido pdf*',
  isLen: 'Tamaño inválido, permitido hasta 1.5MB',
};

export const FormvalidateStatus = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  validating: 'validating',
};

export const ValidatingLoad = {
  hasFeedback: true,
  validateStatus: FormvalidateStatus.validating,
};

export const WariningLoad = {
  hasFeedback: true,
  validateStatus: FormvalidateStatus.warning,
};

export const SuccessLoad = {
  hasFeedback: true,
};

export const ErrorLoads = {
  validateStatus: FormvalidateStatus.error,
};

export const ErrorLoad = (value) => {
  return {
    hasFeedback: true,
    validateStatus: FormvalidateStatus.error,
    help: value,
  };
};

export const SuccesLoad = {
  hasFeedback: true,
  validateStatus: FormvalidateStatus.success,
};

export const openNotificationWithIcon = (message) => {
  notification[message.type]({
    message: message.title,
    description: message.description,
  });
};

export const RequestReturn = {
  OK: 'OK',
  ERROR: 'ERROR',
};

export const MessageSuccess = {
  type: MensajeVariante.success,
  title: 'Gracias por contactarte!',
  description: 'Pronto nos pondremos en contacto contigo.',
};

export const MessageSuccessContact = {
  type: MensajeVariante.success,
  title: 'Email enviado!',
  description: 'Gracias por contactarte con nosotros ',
};

export const MessageRecaptcha = {
  type: MensajeVariante.error,
  title: 'Recaptcha inválido!',
  description: 'Consulte con el administrador',
};

export const MessageBiss = {
  type: MensajeVariante.error,
  title: 'Servidor inválido!',
  description: 'No se pudo conectar con la API',
};

export const ValidatingDefault = {
  hasFeedback: true,
};

export const ValidatorDocument = {
  PASSPORT: 'PASAPORTE',
  IDENTIFICATION: 'CEDULA',
  RUC: 'RUC',
};

export const FieldErr = (value, mensaje) => {
  return {
    value: value,
    errors: [new Error(mensaje)],
  };
};

export const MessageServer = (mensaje, titulo='Alerta') => {
  return {
    type: MensajeVariante.warning,
    title: titulo,
    description: mensaje,
  };
};
