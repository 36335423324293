import firebase from "./Firebase";
const db = firebase.firestore();

class BanerService {
  getAllBaner = (observer) => {
    
    return db
      .collection("Baner")
      .orderBy("orden", "asc")
      .onSnapshot(observer);
  };

}

export default new BanerService();