import React from 'react';
import { PageClasses } from '../../../source/classes/page/PageClasses';
import { PageStyle } from '../../../source/style/page/PageStyle';
import { Row, Col } from 'antd';
import { PageValidation } from './../../../source/validation/page/PageValidation';
import { MenuItemSource } from '../../../source/component/menu/MenuSource';
import { ListItems,ListItemsPl, ListItemsWL } from '../../../function/page/PageFunction';
import { connect } from 'react-redux';
import { HeaderLogo } from './../../../function/header/HeaderFunction';
import { PageImage } from '../../../source/image/PageImage';
import { SocialSCE } from '../../../function/social/SocialFunction';
import {
  Paragraph144RR,
  Paragraph096RL,
} from '../../../function/paragraph/ParagraphFunction';
import { useParams } from "react-router-dom";

function FooterSection(params) {

  const { isMobile, handleCurrent, itemMenu } = params;
  const { col0, col1, social,politicas, information } = MenuItemSource;
  const margi = !isMobile && PageStyle.margin1;
  const logo = PageImage.menu.Limg20;
  const justify = PageClasses.justifyStart;
  const onFocus = (e) => {
    handleCurrent(e.to);

  };
  return (
    <div {...PageClasses.page} {...PageStyle.background1}>
      <div {...margi}>
        <Row
          gutter={[PageValidation.var13, PageValidation.var13]}
          justify={PageValidation.center}
        >
          <Col {...col0}>
            <HeaderLogo
              phone={isMobile}
              logo={logo}
              justify={justify}
            ></HeaderLogo>
            <ListItems
              onFocus={onFocus}
              item={itemMenu}
              color={PageValidation.color0}
              align={PageValidation.left}
            ></ListItems>
            <div {...PageStyle.padding0}>
              <SocialSCE
                social={social}
                isMobile={isMobile}
                align={PageStyle.align2}
                footer={true}
                color={PageValidation.color0}
              ></SocialSCE>
            </div>
          </Col>
          <Col {...col1}>
          <Paragraph144RR
              value={politicas.title}
              color={PageValidation.color0}
              align={PageValidation.justify}
            ></Paragraph144RR>
            <ListItemsPl
              onFocus={onFocus}
              item={politicas.list}
              color={PageValidation.color0}
              align={PageValidation.left}
            ></ListItemsPl>
   
          </Col>
          <Col {...col1}>
            <Paragraph144RR
              value={information.title}
              color={PageValidation.color0}
              align={PageValidation.justify}
            ></Paragraph144RR>
            <Paragraph096RL
              value={information.content}
              color={PageValidation.color0}
              align={PageValidation.justify}
            ></Paragraph096RL>
            {information.data.map((elem, index) => (
              <div {...PageStyle.padding6} key={index}>
                <ListItemsWL
                  item={elem}
                  color={PageValidation.color0}
                  align={PageValidation.start}
                ></ListItemsWL>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  current: state.current,
});

const mapDispatchToProps = (dispatch) => ({
  handleCurrent(value) {
    dispatch({
      type: 'Add_Current',
      value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterSection);
