import React from 'react';
import { Form, Row, Col, Button } from 'antd';

function SocialForm(params) {
  const { submit, form, linkedin, fileList } = params;
  const isFormValid = form.getFieldsError().some(({ errors }) => errors.length)
  return (
    <Row gutter={[8, 8]} justify={'end'}>
      <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item shouldUpdate={true}>
              {() => (
                  <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isFormValid}
                      block
                  >
                      {submit.button}
                  </Button>
              )}
          </Form.Item>
      </Col>
    </Row>
  );
}

export default SocialForm;
