import React, { useState } from 'react';
import { whiteSpace } from './../../../../../script/caracter/CaracterScript';
import { RowR10 } from './../../../../../function/row/RowFunction';
import { AboutSource } from './../../../../../source/component/home/aboutus/AboutUsResource';
import { PageValidation } from './../../../../../source/validation/page/PageValidation';
import { MenuSource } from './../../../../../source/validation/menu/MenuValidation';
import TweenOne from 'rc-tween-one';
import ModalDetail from '../../../../../function/modal/ModalDetail';
function AboutUsSection(params) {
  const { isMobile } = params;
  const [ok, setOk] = useState(false);
  const [modalDetail, setModalDetail] = useState(null);
  const { title, content, img, expand } = AboutSource;
  return (
    <div id={`${whiteSpace(MenuSource.ABOUTUS)}`}>
      <RowR10
        isMobile={isMobile}
        content={content}
        image={img}
        img={false}
        title={title}
        color={PageValidation.color0}
        expand={expand}
        setOk={setOk}
        setModalDetail={setModalDetail}
      ></RowR10>
      {modalDetail && (
        <TweenOne key="Modal">
          <ModalDetail
            image={modalDetail.image}
            title={modalDetail.title.content}
            content={modalDetail.content}
            ok={ok}
            setOk={setOk}
            isMobile={isMobile}
          ></ModalDetail>
        </TweenOne>
      )}
    </div>
  );
}

export default AboutUsSection;
