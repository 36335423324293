import React, { useState } from 'react';
import store from '../../store/Store';
import { SaveState } from '../../store/Localstorage';
import ResizeObserver from 'rc-resize-observer';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './../header/Header';
import { PageClasses } from './../../source/classes/page/PageClasses';
import { Provider } from 'react-redux';
import Footer from './../footer/Footer';
import { MenuItemSource } from './../../source/component/menu/MenuSource';
import RouteDom from '../../route/Route';
import { CookieBanner } from '../Cookies';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

function App(params) {
  const [isMobile, setIsMobile] = useState(null);
  const [itemMenu, setItemMenu] = useState(MenuItemSource.menu);

  store.subscribe(() => {
    SaveState(store.getState());
  });
  const onResize = (value) => {
    parseInt(value.width) < 751 && setIsMobile(true);
    parseInt(value.width) >= 751 && setIsMobile(false);
  };
  const constructor = [
    <ResizeObserver key={0} onResize={onResize}>
      <div></div>
    </ResizeObserver>,
    itemMenu && (
      <Header
        itemMenu={itemMenu}
        key={1}
        {...params}
        isMobile={isMobile}
      />
    ),
    <RouteDom
      key={3}
      isMobile={isMobile}
      setItemMenu={setItemMenu}
      itemMenu={itemMenu}
    />,
    itemMenu && (
      <Footer key={4} isMobile={isMobile} itemMenu={itemMenu} />
    ),
  ];
  return (
    <CookiesProvider>
      <Helmet>
        {/* Define el título de la página */}
        <title>AFS España - COMUNICACIÓN, PUBLICIDAD, ASISTENCIA PERSONALIZADA</title>
      </Helmet>
      <Provider key="provider" store={store}>
        <div>
          <CookieBanner
            message="Administrar consentimiento de cookies"
            showAcceptSelectionButton={true}
            onAcceptPreferences={() => {
              // load your preferences trackers here
            }}
            onAcceptStatistics={() => {
              // load your statistics trackers here
            }}
            onAcceptMarketing={() => {
              // load your marketing trackers here
            }}
            onCancelAll={() => {
              // load your marketing trackers here
            }}

          />
        </div>,
        <Router>
          <div {...PageClasses.wrapper}>{constructor}</div>
        </Router>
      </Provider>
    </CookiesProvider>


  );
}

export default App;
