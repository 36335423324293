import firebase from "./Firebase";
const db = firebase.firestore();

class DocumentosService {

  getDocumentoById = (IdDocumento) => {
    return db
      .collection("Documentos")
      .doc(IdDocumento)
      .get();
  };
}

export default new DocumentosService();