import { PageValidation } from '../../validation/page/PageValidation';

export const RowStyle = {
  bannerI150: {
    style: {
      backgroundRepeat: PageValidation.norepeat,
      backgroundSize: PageValidation.cover,
      backgroundPosition: PageValidation.center,
      minHeight: 150,
    },
  },
  bannerI500: {
    style: {
      backgroundRepeat: PageValidation.norepeat,
      backgroundSize: PageValidation.cover,
      backgroundPosition: PageValidation.center,
      minHeight: 450,
    },
  },
  row: { style: { alignItems: PageValidation.center } },
};
