import React, { useRef, useEffect, useState } from 'react';
import ScrollAnim from 'rc-scroll-anim';
import { Form, Skeleton } from 'antd';
import { whiteSpace } from './../../../constant/whitespace/WhiteSpace';
import { connect } from 'react-redux';
import RenderHTML from './../home/jobs/form/RenderHTML';
import { MenuSource } from './../../../source/validation/menu/MenuValidation';
import { useParams } from "react-router-dom";
import { DocumentosService } from "../../../service";
import { MenuMultiple } from './../../../source/component/menu/MenuMultiple';
import { useHistory } from "react-router-dom";

function Documentos(params) {
  const { idDocumento } = useParams();
  const [servicioSelect, setServicioSelect] = React.useState();
  const [loadign, setLoading] = React.useState(true);
  const Links = ScrollAnim.Link;
  const [form] = Form.useForm();
  const {
    isMobile,
    current,
    handleRedirect,
    itemMenu,
    setItemMenu
  } = params;
  const scrollTo = current && itemMenu.filter((s) => s.name === current) ? itemMenu.filter((s) => s.name === current)[0] : false;
  const event = useRef(null);
  let history = useHistory();

  const responseInfo = (res) => {
  }
  useEffect(
    (async) => {
      event.current.click();
      setItemMenu(MenuMultiple.jobs);
      getServicio()
    },
    [setItemMenu,idDocumento]
  )

  const getServicio = () => {
    DocumentosService.getDocumentoById(idDocumento).then((res) => {
      setServicioSelect(res.data());
      setLoading(false)

    })

  }


  const children = [
    <Links
      key={0}
      to={`${scrollTo ? scrollTo.name : whiteSpace(MenuSource.HOME)}`}
      offsetTop={50}
    >
      <div ref={event}></div>
    </Links>,

    <RenderHTML
      isMobile={isMobile}
      form={form}
      handleRedirect={handleRedirect}
      response={responseInfo}
      Jbsimg1={servicioSelect}
    ></RenderHTML>
  ]
  

  return children;




}

const mapStateToProps = (state) => ({
  current: state.current,
  linkedin: state.linkedin,
  response: state.response,
});

const mapDispatchToProps = (dispatch) => ({
  handleCurrent(value) {
    dispatch({
      type: 'Add_Current',
      value,
    });
  },

  handleLinkedin(value) {
    dispatch({
      type: 'Add_Linkedin',
      value,
    });
  },
  handleResponse(value) {
    dispatch({
      type: 'Add_Response',
      value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Documentos);
