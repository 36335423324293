import Limg0 from '../../assets/img/svg/logo.svg';
import Limg1 from '../../assets/img/svg/logo-w.svg';
import Limg20 from '../../assets/img/austroB.png';

import Limg2 from '../../assets/img/png/inps.png';
import Limg3 from '../../assets/img/png/bissErp/aplications_web/biss/logo.png';

import Lkimg0 from '../../assets/img/svg/linkedin.svg';
import Lkimg1 from '../../assets/img/svg/twitter-seeklogo.com.svg';
import Lkimg2 from '../../assets/img/svg/icons8-youtube.svg';



import Glyimg0 from '../../assets/img/jpg/homebanner/1.jpg';
import Glyimg1 from '../../assets/img/jpg/homebanner/2.jpg';
import Glyimg2 from '../../assets/img/jpg/homebanner/3.jpg';
import Glyimg3 from '../../assets/img/jpg/homebanner/4.jpg';

import Abuimg0 from '../../assets/img/jpg/aboutUs/1.jpg';

import Misimg0 from '../../assets/img/jpg/mission/1.jpg';
import VideoMision from '../../assets/img/jpg/mission/3 - Misión, visión y servicio al cliente.mp4';
import Misimg1 from '../../assets/img/jpg/mission/logo/1.jpg';
import Misimg2 from '../../assets/img/jpg/mission/logo/2.jpg';
import Misimg3 from '../../assets/img/jpg/mission/logo/3.jpg';
import Misimg4 from '../../assets/img/jpg/mission/logo/4.jpg';

import Jbsimg0 from '../../assets/img/jpg/jobs/1.jpg';
import Jbsimg1 from '../../assets/img/jpg/jobs/2.jpg';

import Serimg0 from '../../assets/img/svg/service/1.svg';
import Serimg1 from '../../assets/img/svg/service/2.svg';
import Serimg2 from '../../assets/img/svg/service/3.svg';
import {
  ClientGallery1,
  ClientGallery2,
  ClientGallery3,
} from './gallery/ClientGallery';

import Gnrimg0 from '../../assets/img/png/general/phone.png';
import Gnrimg1 from '../../assets/img/png/general/marker.png';
import Gnrimg2 from '../../assets/img/png/general/mailing.png';
import Gnrimg3 from '../../assets/img/png/close.png';

import Cmpimg0 from '../../assets/img/jpg/company/1.jpg';
import Cmpimg1 from '../../assets/img/jpg/company/2.jpg';

import BssErpimg1 from '../../assets/img/png/bissErp/1.png';
import BssErpimg2 from '../../assets/img/png/bissErp/2.png';
import BssErpimg3 from '../../assets/img/png/bissErp/module/1.png';
import BssErpimg3_1 from '../../assets/img/png/bissErp/module/icon/0.png';
import BssErpimg3_2 from '../../assets/img/png/bissErp/module/1/0.png';
import BssErpimg3_3 from '../../assets/img/png/bissErp/module/1/1.png';
import BssErpimg3_4 from '../../assets/img/png/bissErp/module/1/2.png';

import BssErpimg4 from '../../assets/img/png/bissErp/module/2.png';
import BssErpimg4_0 from '../../assets/img/png/bissErp/module/2/0.png';
import BssErpimg4_1 from '../../assets/img/png/bissErp/module/2/1.png';
import BssErpimg4_2 from '../../assets/img/png/bissErp/module/2/2.png';
import BssErpimg4_3 from '../../assets/img/png/bissErp/module/2/3.png';
import BssErpimg4_4 from '../../assets/img/png/bissErp/module/2/4.png';
import BssErpimg4_5 from '../../assets/img/png/bissErp/module/2/5.png';
import BssErpimg4_6 from '../../assets/img/png/bissErp/module/2/6.png';
import BssErpimg4_7 from '../../assets/img/png/bissErp/module/2/7.png';
import BssErpimg4_8 from '../../assets/img/png/bissErp/module/2/8.png';
import BssErpimg4_9 from '../../assets/img/png/bissErp/module/2/9.png';
import BssErpimg4_10 from '../../assets/img/png/bissErp/module/2/10.png';
import BssErpimg4_11 from '../../assets/img/png/bissErp/module/2/11.png';
import BssErpimg4_12 from '../../assets/img/png/bissErp/module/2/12.png';
import BssErpimg4_13 from '../../assets/img/png/bissErp/module/2/13.png';

import BssErpimg5 from '../../assets/img/png/bissErp/module/3.png';
import BssErpimg5_0 from '../../assets/img/png/bissErp/module/3/0.png';
import BssErpimg5_1 from '../../assets/img/png/bissErp/module/3/1.png';
import BssErpimg5_2 from '../../assets/img/png/bissErp/module/3/2.png';
import BssErpimg5_3 from '../../assets/img/png/bissErp/module/3/3.png';

import BssErpimg6 from '../../assets/img/png/bissErp/module/4.png';
import BssErpimg6_0 from '../../assets/img/png/bissErp/module/4/0.png';
import BssErpimg6_1 from '../../assets/img/png/bissErp/module/4/1.png';
import BssErpimg6_2 from '../../assets/img/png/bissErp/module/4/2.png';
import BssErpimg6_3 from '../../assets/img/png/bissErp/module/4/3.png';
import BssErpimg6_4 from '../../assets/img/png/bissErp/module/4/4.png';
import BssErpimg6_5 from '../../assets/img/png/bissErp/module/4/5.png';
import BssErpimg6_6 from '../../assets/img/png/bissErp/module/4/6.png';

import BssErpimg7_0 from '../../assets/img/png/bissErp/module/web/0.png';
import BssErpimg7_1 from '../../assets/img/png/bissErp/module/web/1.png';
import BssErpimg7_2 from '../../assets/img/png/bissErp/module/web/2.png';
import BssErpimg7_3 from '../../assets/img/png/bissErp/module/web/3.png';
import BssErpimg7_4 from '../../assets/img/png/bissErp/module/web/4.png';
import BssErpimg7_5 from '../../assets/img/png/bissErp/module/web/5.png';
import BssErpimg7_6 from '../../assets/img/png/bissErp/module/web/6.png';
import BssErpimg7_7 from '../../assets/img/png/bissErp/module/web/7.png';
import BssErpimg7_8 from '../../assets/img/png/bissErp/module/web/8.png';
import BssErpimg7_9 from '../../assets/img/png/bissErp/module/web/9.png';
import BssErpimg7_10 from '../../assets/img/png/bissErp/module/web/10.png';

import BssErpimg8_0 from '../../assets/img/png/bissErp/module/mobile/0.png';
import BssErpimg8_1 from '../../assets/img/png/bissErp/module/mobile/1.png';
import BssErpimg8_2 from '../../assets/img/png/bissErp/module/mobile/2.png';
import BssErpimg8_3 from '../../assets/img/png/bissErp/module/mobile/3.png';
import BssErpimg8_4 from '../../assets/img/png/bissErp/module/mobile/4.png';

import BssErpimg9_0 from '../../assets/img/png/bissErp/module/business/0.png';
import BssErpimg9_1 from '../../assets/img/png/bissErp/module/business/1.png';
import BssErpimg9_2 from '../../assets/img/png/bissErp/module/business/2.png';
import BssErpimg9_3 from '../../assets/img/png/bissErp/module/business/3.png';
import BssErpimg9_4 from '../../assets/img/png/bissErp/module/business/4.png';
import BssErpimg9_5 from '../../assets/img/png/bissErp/module/business/5.png';
import BssErpimg9_6 from '../../assets/img/png/bissErp/module/business/6.png';
import BssErpimg9_7 from '../../assets/img/png/bissErp/module/business/7.png';
import BssErpimg9_8 from '../../assets/img/png/bissErp/module/business/8.png';
import BssErpimg9_9 from '../../assets/img/png/bissErp/module/business/9.png';

export const PageImage = {
  menu: {
    Limg0,
    Limg1,
    Limg2,
    Limg3,
    Limg20
  },
  social: {
    Lkimg0,Lkimg1,Lkimg2
  },
  homeGallery: {
    Glyimg0,
    Glyimg1,
    Glyimg2,
    Glyimg3,
  },
  aboutUs: {
    Abuimg0,
  },
  mission: { Misimg0, Misimg1, Misimg2, Misimg3, Misimg4,VideoMision },
  service: { Serimg0, Serimg1, Serimg2 },
  client: {
    gallery1: ClientGallery1,
    gallery2: ClientGallery2,
    gallery3: ClientGallery3,
  },
  jobs: { Jbsimg0, Jbsimg1 },
  general: { Gnrimg0, Gnrimg1, Gnrimg2, Gnrimg3 },
  company: { Cmpimg0, Cmpimg1 },
  erpbiss: {
    BssErpimg1,
    BssErpimg2,
    BssErpimg3,
    BssErpimg3Item: [
      { img: BssErpimg3_2 },
      { img: BssErpimg3_3 },
      { img: BssErpimg3_4 },
    ],
    BssErpimg3_1,
    BssErpimg4,
    BssErpimg4Item: [
      { img: BssErpimg4_0 },
      { img: BssErpimg4_1 },
      { img: BssErpimg4_2 },
      { img: BssErpimg4_3 },
      { img: BssErpimg4_4 },
      { img: BssErpimg4_5 },
      { img: BssErpimg4_6 },
      { img: BssErpimg4_7 },
      { img: BssErpimg4_8 },
      { img: BssErpimg4_9 },
      { img: BssErpimg4_10 },
      { img: BssErpimg4_11 },
      { img: BssErpimg4_12 },
      { img: BssErpimg4_13 },
    ],
    BssErpimg5,
    BssErpimg5Item: [
      { img: BssErpimg5_0 },
      { img: BssErpimg5_1 },
      { img: BssErpimg5_2 },
      { img: BssErpimg5_3 },
    ],
    BssErpimg6,
    BssErpimg6Item: [
      { img: BssErpimg6_0 },
      { img: BssErpimg6_1 },
      { img: BssErpimg6_2 },
      { img: BssErpimg6_3 },
      { img: BssErpimg6_4 },
      { img: BssErpimg6_5 },
      { img: BssErpimg6_6 },
    ],
  },
  BssErpimg7: {
    BssErpimg7_0,
    BssErpimg7_1,
    BssErpimg7_2,
    BssErpimg7_3,
    BssErpimg7_4,
    BssErpimg7_5,
    BssErpimg7_6,
    BssErpimg7_7,
    BssErpimg7_8,
    BssErpimg7_9,
    BssErpimg7_10,
  },
  BssErpimg8: {
    BssErpimg8_0,
    BssErpimg8_1,
    BssErpimg8_2,
    BssErpimg8_3,
    BssErpimg8_4,
  },
  BssErpimg9: {
    BssErpimg9_0,
    BssErpimg9_1,
    BssErpimg9_2,
    BssErpimg9_3,
    BssErpimg9_4,
    BssErpimg9_5,
    BssErpimg9_6,
    BssErpimg9_7,
    BssErpimg9_8,
    BssErpimg9_9,
  },
};
