import React, { useState, useEffect, useRef } from 'react';
import { Affix, Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import HeaderItems from './HeaderItems';
import { PageStyle } from './../../source/style/page/PageStyle';
import { PageClasses } from './../../source/classes/page/PageClasses';
import { PageAnimation } from './../../source/animation/page/PageAnimation';
import { MenuIconPage } from './../../function/page/PageFunction';
import { HeaderLogo } from './../../function/header/HeaderFunction';
import { PageImage } from '../../source/image/PageImage';
import { MenuStyle } from '../../source/classes/menu/MenuStyle';
import { useLocation } from "react-router-dom";

function HeaderMenu(params) {
  let location = useLocation();
  const [top] = useState(0);
  const { isMobile, itemMenu } = params;
  const menuRef = useRef(null);
  const [phoneOpen, setPhoneOpen] = useState(undefined);
  const theme = { theme: isMobile ? 'dark' : 'light' };
  const mode = !isMobile && { mode: 'horizontal' };
  const menustyle = isMobile ? MenuStyle.menuMobile : MenuStyle.menu;
  const moment = phoneOpen ? 300 : null;
  const fondo = isMobile ? { style: { backgroundColor: '#919191' } } : {};
  const menuStyleMobile = isMobile
    ? PageClasses.tweenOne.option2
    : PageClasses.tweenOne.option3;
  const menuMobile = isMobile
    ? PageClasses.div.option4
    : PageClasses.div.option3;

  const logo = isMobile ? PageImage.menu.Limg1 : PageImage.menu.Limg0;
  const justify = PageClasses.justifyStart;

  useEffect(() => {
    setPhoneOpen(isMobile ? false : true);
    if (!isMobile) {
      menuRef.current.dom.style = 'height:unset';
    }
  }, [isMobile]);

  const phoneClick = () => {
    const value = !phoneOpen;
    setPhoneOpen(value);
  };
  const onComplete = (e) => {
    e.target.style.height = 'auto';
  };

  return (
    <div>
  
      <Affix offsetTop={top}>
        <div {...PageStyle.background0}>
          <div {...PageClasses.menu1} {...PageStyle.margin1} {...fondo}>
            <TweenOne
              key="0"
              {...PageClasses.tweenOne.option1}
              {...PageAnimation.animation1}
            >
              <Row
                {...PageClasses.Row.option1}
                className={`${PageClasses.div.option1}${
                  phoneOpen ? ' open' : ''
                }`}
              >
                <TweenOne
                  key="1"
                  {...PageAnimation.animation2}
                  {...PageClasses.logo.header}
                  component={Col}
                >
                  <HeaderLogo
                    phone={isMobile}
                    logo={logo}
                    justify={justify}
                  ></HeaderLogo>
                </TweenOne>
                {isMobile && (
                  <Col
                    {...PageStyle.menuIcon}
                    {...PageClasses.div.option2}
                    onClick={() => {
                      phoneClick();
                    }}
                  >
                    {MenuIconPage.menu.icon}
                  </Col>
                )}

                <TweenOne
                  key="2"
                  component={Col}
                  {...menuMobile}
                  {...menuStyleMobile}
                  ref={menuRef && menuRef}
                  animation={
                    isMobile
                      ? {
                          onComplete: phoneOpen && onComplete,
                          ...PageAnimation.animation3,
                        }
                      : null
                  }
                  moment={moment}
                  reverse={!!phoneOpen}
                >
                 <HeaderItems
                    itemMenu={itemMenu}
                    isMobile={isMobile}
                    phoneOpen={phoneOpen}
                    theme={theme}
                    mode={mode}
                    menustyle={menustyle}
                  ></HeaderItems>
                </TweenOne>
              </Row>
            </TweenOne>
          </div>
        </div>
      </Affix>
    </div>
  );
}

export default HeaderMenu;
