import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteUrl } from '../source/route/RouteSource';
import Home from './../component/body/home/Home';
import ProductoDetalle from './../component/body/ProductoDetalle/ProductoDetalle';
import Documentos from './../component/body/Documentos/Documentos';


function RouteDom(params) {
  const { isMobile, setItemMenu, itemMenu } = params;
  function handleRedirect(_params, ruta) {
    _params.push(ruta);
  }
  return (
    <Switch>
      <Route
        exact
        path={`${RouteUrl.HOME}`}
        render={(_params) => (
          <Home
            setItemMenu={setItemMenu}
            itemMenu={itemMenu}
            handleRedirect={handleRedirect}
            isMobile={isMobile}
            {..._params}
          ></Home>
        )}
      />

      <Route
        path={`${RouteUrl.SERVICIO}`}
        render={(_params) => (
          <ProductoDetalle
            setItemMenu={setItemMenu}
            itemMenu={itemMenu}
            handleRedirect={handleRedirect}
            isMobile={isMobile}
            {..._params}
          ></ProductoDetalle>
        )}
      />
      <Route
        path={`${RouteUrl.DOCUMETOS}`}
        render={(_params) => (
          <Documentos
            setItemMenu={setItemMenu}
            itemMenu={itemMenu}
            handleRedirect={handleRedirect}
            isMobile={isMobile}
            {..._params}
          ></Documentos>
        )}
      />
 
    </Switch>
  );
}

export default RouteDom;
