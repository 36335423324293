import React from 'react';
import { Paragraph20RR } from '../../../../../function/paragraph/ParagraphFunction';
import { OurServiceSource } from './../../../../../source/component/home/ourservices/OurServiceSoruce';
import { PageClasses } from './../../../../../source/classes/page/PageClasses';
import { RowC3 } from '../../../../../function/row/RowFunction';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import { PageAnimation } from './../../../../../source/animation/page/PageAnimation';
import { PageStyle } from '../../../../../source/style/page/PageStyle';
import { ServiciosService } from "../../../../../service";


const ScrollOverPack = ScrollAnim.OverPack;

function OurServiceSection(params) {
  const { title,  col } = OurServiceSource;
  const { isMobile ,history, handleRedirect    , handleCurrent,} = params;
  const margi = !isMobile && PageStyle.margin1;
  React.useEffect(() => {
    getBanner()
  }, []);
  const [items, setItems] = React.useState([]);

  const getBanner = React.useCallback(() => {
    ServiciosService.getAllServicios(
      {
        next: (querySnapshot) => {
          const Items = querySnapshot.docs.map((docSnapshot) => docSnapshot);
          setItems(Items);


        },
      })
  }, []);
  return (
    
    <div {...PageClasses.menu12} {...margi}>
      <div {...PageStyle.padding0}>
        <Paragraph20RR
          value={title.content}
          color={title.color}
          align={title.align}
        ></Paragraph20RR>
      </div>
      <div {...PageStyle.padding0}>
        <ScrollOverPack
          key={0}
          {...PageAnimation.scrollAnim.anim}
          {...PageClasses.imgwrapper}
        >
          <TweenOne
            key={1}
            {...PageClasses.block}
            {...PageAnimation.scrollAnim.anim4}
          >
            <RowC3 
                  colvalue={col} element={items} 
                  history={history}
                  handleRedirect={handleRedirect}
                  handleCurrent={handleCurrent}
            
            
            ></RowC3>

          </TweenOne>
        </ScrollOverPack>
      </div>
    </div>
  );
}

export default OurServiceSection;
