import React from 'react';
import BannerAnim from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import '../../../assets/less/banner.less';
import '../../../assets/less/_banner.less';
import '../../../assets/less/thumb.less';
import { PageValidation } from './../../../source/validation/page/PageValidation';
import { PageAnimation } from './../../../source/animation/page/PageAnimation';
import { PageClasses } from './../../../source/classes/page/PageClasses';
import { PageStyle } from './../../../source/style/page/PageStyle';

import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

function GalleryFunction(params) {
  const {
    gallery,
    isMobile,
    height,
    text,
    style,
    button,
    backGallery,
  } = params;
  const autoHeight = height && {
    style: {
      height: isMobile ? PageValidation.var21 : height,
      ...PageStyle.bannerStatic.style,
    },
  };
  return (
    <BannerAnim {...autoHeight} {...PageAnimation.bannerAnimation.animation1}>
      {gallery.map((item, index) => (
        <Element key={index} {...PageClasses.element.class1} style={{ marginTop: isMobile ? '0px' : '-55px' }}>
          <BgElement
            key={`-${index}`}
            {...PageClasses.element.class2}
            style={{
              backgroundImage: `url(${item?.src})`,
              ...style,
            }}
          />
          <div style={{
            position: 'relative',
            paddingTop: isMobile ? "48.25%" : "56.25%",
          }}>

            <ReactPlayer url={item?.src}
              className="react-player"
              width={'100%'}
              loop={true}
              playing={true}
              playsinline={true}
              volume={0.5}
              muted={true}
              height='100%'
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>

          {button && (
            <QueueAnim name="QueueAnim">
              <div {...PageClasses.menu} {...PageStyle.margin1}>
                <div {...PageStyle.paddingLeft22}>
                  <Button type="text" onClick={backGallery}>
                    <LeftOutlined />
                    Volver
                  </Button>
                </div>
              </div>
            </QueueAnim>
          )}
        </Element>
      ))}
    </BannerAnim>
  );
}

export default GalleryFunction;
