import firebase from "./Firebase";
const db = firebase.firestore();

class LocalidadesService {
  getLocalidades = (observer) => {
    
    return db
      .collection("Localidades")
      .onSnapshot(observer);
  };

}

export default new LocalidadesService();