const afspana = 'afspana-page';
const inpspage = 'inps-page';
const wrapper = 'wrapper';
const homePage = 'home-page';
const header = 'header';

export const PageClasses = {
  wrapper: { className: `${inpspage}-${wrapper} ${afspana}-${wrapper}` },
  page: { className: `${inpspage} ${afspana} ` },
  menu: { className: `${inpspage} ${afspana} ${inpspage}-none` },
  menu1: { className: `${inpspage} ${afspana} ${inpspage}-none1` },
  menu12: { className: `${inpspage} ${afspana} ${inpspage}-none21` },
  
  padding: { className: `${inpspage} ${afspana} ${inpspage}-padding` },
  imgwrapper: { className: `${afspana}-img-${wrapper}` },
  blockContent: { className: `${afspana}-block-content` },
  block: { className: 'block' },
  justifyCenter: { justify: 'center' },
  justifyEnd: { justify: 'end' },
  justifyStart: { justify: 'start' },
  justifyContentbetween: { justifyContent: 'space-between',with:'100%' },

  placementLeft: { placement: 'left' },
  typeWarning: { type: 'warning' },
  tweenOne: {
    option1: {
      component: header,
      className: `${header}0 ${homePage}-wrapper`,
    },
    option2: { className: `${header}0-menu` },
    option3: { className: `${header}0-menu aje-menu` },
  },
  Row: {
    option1: { gutter: { xs: 8, sm: 16, md: 24, lg: 32 }, justify: 'start' },
  },
  div: {
    option1: `${homePage} k8xaortjtch-editor_css`,
    option2: { className: `${header}0-mobile-menu`, xs: 6, sm: 6, md: 6 },
    option3: { xs: 32, sm: 16, md: 16, lg: 18 },
    option4: { xs: 32, md: 16, lg: 18 },
  },
  logo: {
    header: { className: `${header}0-logo`, xs: 18, sm: 8, md: 8, lg: 6 },
    option1: { style: { maxWidth: '100%', width: '25px' } },
    option2: { style: { maxWidth: '100%', width: '25px' } },
  },
  element: {
    class1: { prefixCls: 'banner-user-elem' },
    class2: { className: 'bg' },
  },
  thumb: {
    class1: { prefixCls: 'user-thumb' },
  },
  tabs0: { className: `css-tab0` },
  col1: { xs: 24, sm: 24, md: { span: 8 }, lg: { span: 8 } },
  col2: { xs: 24, sm: 24, md: 6, lg: 6 },
  col3: { xs: 24, sm: 24, md: 24, lg: 24 },
  col4: { xs: 24, sm: 22, md: 12, lg: 10 },
  col5: {
    xs: 24,
    sm: 24,
    md: 24,
    lg: { span: 20, push: 2 },
  },
  ul0: { className: 'css-ul0' },
};
