import React from 'react';
import MissionSection from './section/MissionSection';

function Mission(params) {
  const { isMobile } = params;
  const children = [
    <MissionSection key={0} isMobile={isMobile}></MissionSection>,
  ];

  return <div>{children}</div>;
}

export default Mission;
