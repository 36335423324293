const from = 'from';
const options = {
  delay: 200,
  opacity: 0,
  type: 'from',
  ease: 'easeInOutSine',
};
export const PageAnimation = {
  animation1: { opacity: 0, type: from },
  animation2: { x: -30, type: from, ease: 'easeOutQuad' },
  animation3: { height: 0, duration: 300, ease: 'easeInOutQuad' },
  animation4: { opacity: 1, x: 0 },
  bannerAnimation: {
    animation1: { autoPlaySpeed: 3000, autoPlayEffect: false },
    animation2: { animation: { bottom: 0 } },
    animation3: { autoPlaySpeed: 3000, autoPlayEffect: false,           backgroundColor:'red',
    marginTop:'-10%'},

  },
  scrollAnim: {
    anim: { playScale: 0.2 },
    anim1: { animation: { y: 0, opacity: 1 } },
    anim2: { animation: { x: 100, ...options } },
    anim3: { animation: { y: 100, ...options } },
    anim4: { animation: { y: 120, ...options } },
    anim5: { animation: { x: -100, ...options } },
    anim6: { animation: { playScale: [0.5, 0.8], x: 0, opacity: 1 } },
    anim7: { animation: { ...options } },
  },
};
