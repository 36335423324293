import React from 'react';
import { Row, Col } from 'antd';
import { MenuStyle } from './../../source/classes/menu/MenuStyle';
import { PageValidation } from './../../source/validation/page/PageValidation';
import { MenuItemSource } from '../../source/component/menu/MenuSource';
import { SocialSCE } from './../social/SocialFunction';
import { PageStyle } from './../../source/style/page/PageStyle';

export function HeaderLogo(params) {
  const { phone, logo, justify } = params;
  const stylelogo = phone ? MenuStyle.menuLogoMobile : MenuStyle.menuLogo;
  return (
    <Row gutter={[8, 8]} {...justify}>
      <Col>
        <a href="/">
        <img {...stylelogo} alt={PageValidation.alt} src={logo} />

        </a>
      </Col>
    </Row>
  );
}



export function TitleText(params) {
  const { element } = params;
  return (
    <div {...PageStyle.padding0}>
      {element?.content?.title}
      <div {...PageStyle.padding0}>{element?.content?.description}</div>
    </div>
  );
}

export function TitleTextLine(params) {
  const { element } = params;
  return (
    <div>
      {element && element?.title}
      {element && element?.line}
    </div>
  );
}
