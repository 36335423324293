import { whiteSpace } from '../../../script/caracter/CaracterScript';
import { RouteUrl } from './../../route/RouteSource';
import { MenuSource } from './../../validation/menu/MenuValidation';

const options = {
  option1: {
    name: whiteSpace(MenuSource.HOME),
    text: `${MenuSource.HOME}`
  },
  option2: {
    name: whiteSpace(MenuSource.ABOUTUS),
    text: `${MenuSource.ABOUTUS}`,
  },
  option3: {
    name: whiteSpace(MenuSource.SERVICE),
    text: `${MenuSource.SERVICE}`,
  },
  option4: {
    name: whiteSpace(MenuSource.OURCLIENTS),
    text: `${MenuSource.OURCLIENTS}`,
  },
  option5: {
    name: whiteSpace(MenuSource.CONTACT),
    text: `${MenuSource.CONTACT}`,
  },

  option6: {
    name: whiteSpace(MenuSource.SERVICED),
    text: `${MenuSource.SERVICED}`,
  },
};

export const MenuMultiple = {
  home: [
    {
      id: 1,
      ...options.option1,
      url: null,
    },
    {
      id: 2,
      ...options.option2,
      url: null,
    },
    {
      id: 3,
      ...options.option3,
      url: null,
    },

    {
      id: 4,
      ...options.option4,
      url: null,
    },
    {
      id: 5,
      ...options.option5,
      url: null,
    },
  ],
  jobs: [
    {
      id: 1,
      ...options.option1,
      url: RouteUrl.HOME,
    },
    {
      id: 2,
      ...options.option2,
      url: RouteUrl.HOME,
    },
    {
      id: 3,
      ...options.option3,
      url: null,
    },

    {
      id: 4,
      ...options.option4,
      url: RouteUrl.HOME,
    },
    {
      id: 5,
      ...options.option5,
      url: null,
    },
  ],
};
