/**Gallery1 */
import Clig1img1 from '../../../assets/img/png/client/ecuador/gallery1/1.png';
import Clig1img2 from '../../../assets/img/png/client/ecuador/gallery1/2.png';
import Clig1img3 from '../../../assets/img/png/client/ecuador/gallery1/3.png';
import Clig1img4 from '../../../assets/img/png/client/ecuador/gallery1/4.png';
import Clig1img5 from '../../../assets/img/png/client/ecuador/gallery1/5.png';
import Clig1img6 from '../../../assets/img/png/client/ecuador/gallery1/6.png';
import Clig1img7 from '../../../assets/img/png/client/ecuador/gallery1/7.png';
import Clig1img8 from '../../../assets/img/png/client/ecuador/gallery1/8.png';
import Clig1img9 from '../../../assets/img/png/client/ecuador/gallery1/9.png';
import Clig1img10 from '../../../assets/img/png/client/ecuador/gallery1/10.png';
import Clig1img11 from '../../../assets/img/png/client/ecuador/gallery1/11.png';
import Clig1img12 from '../../../assets/img/png/client/ecuador/gallery1/12.png';
import Clig1img13 from '../../../assets/img/png/client/ecuador/gallery1/13.png';
import Clig1img14 from '../../../assets/img/png/client/ecuador/gallery1/14.png';
import Clig1img15 from '../../../assets/img/png/client/ecuador/gallery1/15.png';
import Clig1img16 from '../../../assets/img/png/client/ecuador/gallery1/16.png';
import Clig1img17 from '../../../assets/img/png/client/ecuador/gallery1/17.png';
import Clig1img18 from '../../../assets/img/png/client/ecuador/gallery1/18.png';
import Clig1img19 from '../../../assets/img/png/client/ecuador/gallery1/19.png';
import Clig1img20 from '../../../assets/img/png/client/ecuador/gallery1/20.png';
import Clig1img21 from '../../../assets/img/png/client/ecuador/gallery1/21.png';
import Clig1img22 from '../../../assets/img/png/client/ecuador/gallery1/22.png';

/**Gallery2 */
import Clig2img1 from '../../../assets/img/png/client/ecuador/gallery2/1.png';
import Clig2img2 from '../../../assets/img/png/client/ecuador/gallery2/2.png';
import Clig2img3 from '../../../assets/img/png/client/ecuador/gallery2/3.png';
import Clig2img4 from '../../../assets/img/png/client/ecuador/gallery2/4.png';
import Clig2img5 from '../../../assets/img/png/client/ecuador/gallery2/5.png';
import Clig2img6 from '../../../assets/img/png/client/ecuador/gallery2/6.png';
import Clig2img7 from '../../../assets/img/png/client/ecuador/gallery2/7.png';
import Clig2img8 from '../../../assets/img/png/client/ecuador/gallery2/8.png';
import Clig2img9 from '../../../assets/img/png/client/ecuador/gallery2/9.png';
import Clig2img10 from '../../../assets/img/png/client/ecuador/gallery2/10.png';
import Clig2img11 from '../../../assets/img/png/client/ecuador/gallery2/11.png';
import Clig2img12 from '../../../assets/img/png/client/ecuador/gallery2/12.png';

/**Gallery3 */
import Clig3img1 from '../../../assets/img/png/client/colombia/gallery1/1.png';
import Clig3img2 from '../../../assets/img/png/client/colombia/gallery1/2.png';
import Clig3img3 from '../../../assets/img/png/client/colombia/gallery1/3.png';

const col1= { xs: 12, sm: 6, md: 6, lg: 6 };
const col2= { xs: 24, sm: 18, md: 12, lg: 12 };
const col3= { xs: 12, sm: 6, md: 6, lg: 6 };
export const ClientGallery1 = [
  {img:Clig1img1,col:col2},
  {img:Clig1img2,col:col3},
  {img:Clig1img3,col:col1},
  {img:Clig1img4,col:col1},
  {img:Clig1img5,col:col1},
  {img:Clig1img6,col:col1},
  {img:Clig1img7,col:col1},
  {img:Clig1img8,col:col1},
  {img:Clig1img9,col:col1},
  {img:Clig1img10,col:col1},
  {img:Clig1img11,col:col1},
  {img:Clig1img12,col:col1},
  {img:Clig1img13,col:col1},
  {img:Clig1img14,col:col1},
  {img:Clig1img15,col:col1},
  {img:Clig1img16,col:col1},
  {img:Clig1img17,col:col1},
  {img:Clig1img18,col:col1},
  {img:Clig1img19,col:col1},
  {img:Clig1img20,col:col1},
  {img:Clig1img21,col:col1},
  {img:Clig1img22,col:col1},
];
export const ClientGallery2 = [
  {img:Clig2img1,col:col1},
  {img:Clig2img2,col:col1},
  {img:Clig2img3,col:col1},
  {img:Clig2img4,col:col1},
  {img:Clig2img5,col:col1},
  {img:Clig2img6,col:col1},
  {img:Clig2img7,col:col1},
  {img:Clig2img8,col:col1},
  {img:Clig2img9,col:col1},
  {img:Clig2img10,col:col1},
  {img:Clig2img11,col:col1},
  {img:Clig2img12,col:col1},
];
export const ClientGallery3 = [
  {img:Clig3img1, col:col1},
  {img:Clig3img2, col:col1},
  {img:Clig3img3, col:col1}];
