import React, { useState } from 'react';
import { PageClasses } from './../../../../../source/classes/page/PageClasses';
import { CarouselFunction } from './../../../../../function/carousel/CarouselFunction';
import { MissionSource } from './../../../../../source/component/home/mission/MissionSource';
import TweenOne from 'rc-tween-one';
import { CarouselClasses } from './../../../../../source/classes/carousel/CarouselClass';
import { PageStyle } from '../../../../../source/style/page/PageStyle';
import ModalDetail from '../../../../../function/modal/ModalDetail';
import ReactPlayer from 'react-player'


function MissionSection(params) {
  const { isMobile } = params;
  const [ok, setOk] = useState(false);
  const [modalDetail, setModalDetail] = useState(null);
  const { data, img } = MissionSource;
  const style = {
    style: {
      backgroundImage: `url(${img})`,
      ...PageStyle.background2.style,
    },
  };
  const margi = !isMobile && PageStyle.margin1;

  return (
    <div  >
      <div style={{
        position: 'relative',
        paddingTop: isMobile ? "48.25%" : "56.25%",
      }}>

        <ReactPlayer url={img}
          className="react-player"
          width={'100%'}
          loop={true}
          playing={true}
          playsinline={true}
          volume={0.5}
          muted={true}
          height='100%'
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </div>
      <div {...PageClasses.imgwrapper} style={{ marginTop: '-20%' }}>
        <div key={1} {...CarouselClasses.tweenOne} {...margi}>
          <CarouselFunction
            data={data}
            setOk={setOk}
            setModalDetail={setModalDetail}
          ></CarouselFunction>
        </div>
      </div>
      {modalDetail && (
        <TweenOne key="Modal">
          <ModalDetail
            image={modalDetail.img}
            title={modalDetail.title.content}
            content={modalDetail.content.content}
            ok={ok}
            setOk={setOk}
            isMobile={isMobile}
          ></ModalDetail>
        </TweenOne>
      )}
    </div>
  );
}

export default MissionSection;
