import React from 'react';
import { ClientSource } from './../../../../../source/component/home/client/ClientSource';
import { PageClasses } from '../../../../../source/classes/page/PageClasses';
import { RowArrayByLocal, RowArray } from './../../../../../function/row/RowFunction';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import { PageAnimation } from './../../../../../source/animation/page/PageAnimation';
import { PageStyle } from './../../../../../source/style/page/PageStyle';
import { Paragraph20RR } from './../../../../../function/paragraph/ParagraphFunction';
import { LocalidadesService } from "../../../../../service";
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api';
const containerStyle = {
  width: '100%',
  height: '600px'
};
const center = {
  lat: 37.9772383,
  lng: -1.199899
};
const ScrollOverPack = ScrollAnim.OverPack;

function ClientSection(params) {
  const { isMobile } = params;
  const [isOpen, setIsOpen] = React.useState(false);
  const [dataSelect, setDataSelect] = React.useState("");
  const [activeMarker, setActiveMarker] = React.useState(null);
  const [indexSelect, setIndexSelect] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  const onToggleOpen = (res, index) => {
    setIndexSelect(index)
    setDataSelect(res)
    setIsOpen(!isOpen)
  }
  const goGoogle = () => {
    window.open('http://maps.google.com?daddr=' + dataSelect.Latitude + ',' + dataSelect.Longitude + '&amp;ll=', '_blank');

  }


  React.useEffect(() => {
    getServicios()
  }, []);
  const getServicios = React.useCallback(() => {
    setLoading(true)
    LocalidadesService.getLocalidades(
      {
        next: (querySnapshot) => {
          const Items = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
          setItems(Items);
          setLoading(false)

        },
      })

  }, []);
  const { colvalue, title, client, style } = ClientSource;
  const margi = !isMobile && PageStyle.margin1;
  return (
    <div {...PageClasses.page} {...margi}>
      <div {...PageStyle.padding0}>
        <Paragraph20RR
          value={title.content}
          color={title.color}
          align={title.align}
        ></Paragraph20RR>
        <LoadScript
          googleMapsApiKey="AIzaSyCUj4qv5WQj2AvCVPqp4R3EJoy_D5PVd1Q"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            { /* Child components, such as markers, info windows, etc. */}
            {items.map((res, index) =>
              <Marker key={index} position={{ lat: res.Latitude, lng: res.Longitude }} onClick={() => onToggleOpen(res, index)}>
                {isOpen && (indexSelect === index) && <InfoWindow
                  visible={isOpen}
                  marker={activeMarker}
                >
                  <div style={{ padding: 1, flexDirection: "row" }}>
                    <div>
                      {dataSelect.Direccion}
                    </div>
                    <button
                      key={index}
                      style={{
                        color: "#000", backgroundColor: "#fff", borderColor: "#fff",
                        outline: "none",
                        boxShadow: "none"
                      }}
                      onClick={() => goGoogle()}
                    >
                      {'(Indicaciones)'}
                    </button>

                  </div>
                </InfoWindow>}
              </Marker>

            )}

          </GoogleMap>
        </LoadScript>

      </div>

      <ScrollOverPack
        key={0}
        {...PageAnimation.scrollAnim.anim}
        {...PageClasses.imgwrapper}
      >
        <TweenOne
          key={1}
          {...PageClasses.block}
          {...PageAnimation.scrollAnim.anim4}
        >

          <RowArrayByLocal
            isMobile={isMobile}
            element={client}
            localidades={items}
            colvalue={colvalue}
            style={style}
          ></RowArrayByLocal>

        </TweenOne>
      </ScrollOverPack>
    </div>
  );
}

export default ClientSection;
