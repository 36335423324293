import React from 'react';
import GalleryFunction from './../../../../function/banner/gallery/GalleryFunction';
import { HomeBannerSource } from './../../../../source/component/home/banner/HomeBannerSource';
import { whiteSpace } from './../../../../script/caracter/CaracterScript';
import { MenuSource } from './../../../../source/validation/menu/MenuValidation';
import { PageStyle } from './../../../../source/style/page/PageStyle';
import { BanerService } from "../../../../service";

function HomeBanner(params) {
  React.useEffect(() => {
    getBanner()
  }, []);
  const [items, setItems] = React.useState([]);

  const getBanner = React.useCallback(() => {
    BanerService.getAllBaner(
      {
        next: (querySnapshot) => {
          const Items = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
          setItems(Items);


        },
      })
  }, []);
  const { isMobile } = params;
  return (
    <div id={`${whiteSpace(MenuSource.HOME)}`}>
      <GalleryFunction
        gallery={items}
        height={'105vh'}
        isMobile={isMobile}
        text={true}
        style={PageStyle.background2.style}
      ></GalleryFunction>
    </div>
  );
}

export default HomeBanner;
