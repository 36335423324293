import React from 'react';
import {
  PageDetail096RSMBS,
  PageDetailCS084C,
} from './../paragraph/ParagraphFunction';
import { Tooltip, Button, Row, Col, Alert } from 'antd';
import { PageStyle } from './../../source/style/page/PageStyle';
import { PageValidation } from './../../source/validation/page/PageValidation';
import { PageClasses } from './../../source/classes/page/PageClasses';
import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import { PageAnimation } from './../../source/animation/page/PageAnimation';

export function SocialSCE(params) {
  const { social, align, footer, color } = params;
  const style = align && align;
  return (
    <div {...style}>
      {social.title && (
        <div>
          {footer && (
            <SocialTitleS084
              title={social.title}
              color={color}
            ></SocialTitleS084>
          )}
        </div>
      )}
      {social.list.map((data, index) => (
        <Tooltip key={index} title={data.name}>
          <Button type="link" {...PageStyle.padding4p10p}>
            {/* eslint-disable-next-line */}
            <a href={data.link} target="_blank">
              <img src={data.icon} alt={PageValidation.alt}></img>
            </a>
          </Button>
        </Tooltip>
      ))}
    </div>
  );
}

//Social title start font size 0.96 rem
export function SocialTitle(params) {
  const { title, color } = params;
  return <PageDetail096RSMBS value={title} color={color} />;
}

//Social title start font size 0.84 rem
export function SocialTitleS084(params) {
  const { title, color } = params;
  return <PageDetailCS084C value={title} color={color} />;
}

export function ProfileValue(params) {
  const { profile, email } = params;

  return {
    name: profile.firstName.localized.es_ES,
    lastname: profile.lastName.localized.es_ES,
    email: email.elements[0]['handle~'].emailAddress,
  };
}

export function SocialMessage(params) {
  const { message } = params;
  const onClose = (e) => {
    console.log(e, 'I was closed.');
  };
  return (
    <Alert
      message={message.message}
      type={message.type}
      closable
      onClose={onClose}
    />
  );
}

export function SocialProfile(params) {
  const { profile, isMobile } = params;
  const photo =
    profile.photo['displayImage~'].elements[0].identifiers[0].identifier;
  const style = isMobile ? PageStyle.profileC : PageStyle.textAlignS;
  return (
    photo && (
      <Row {...PageClasses.justifyStart} {...PageStyle.row}>
        <Col xs={24} sm={24} md={10} lg={6}>
          <div {...style}>
            <img src={photo} alt=""></img>
          </div>
        </Col>
        <Col xs={24} sm={24} md={14} lg={18}>
          <OverPack {...PageAnimation.anim} style={{ overflow: 'hidden' }}>
            <TweenOne key="name" {...PageAnimation.anim2}>
              {profile.data.name && !isMobile && (
                <PageDetail096RSMBS
                  value={profile.data.name}
                  color={PageValidation.color1}
                />
              )}
            </TweenOne>
            <TweenOne key="lastname" {...PageAnimation.scrollAnim.anim2}>
              {profile.data.lastname && !isMobile && (
                <PageDetail096RSMBS
                  value={profile.data.lastname}
                  color={PageValidation.color1}
                />
              )}
            </TweenOne>
            <TweenOne key="email" {...PageAnimation.anim2}>
              {profile.data.email && !isMobile && (
                <PageDetail096RSMBS
                  value={profile.data.email}
                  color={PageValidation.color1}
                />
              )}
            </TweenOne>
          </OverPack>
        </Col>
      </Row>
    )
  );
}
