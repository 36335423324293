import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from "firebase/analytics";

import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGI,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUR,

};
const firebaseApp = firebase.initializeApp(config);
const analytics = getAnalytics(firebaseApp);
logEvent(analytics, 'select_content', {
  content_type: 'image',
  content_id: 'P12453',
  items: [{ name: 'Kittens' }]
});

export default firebaseApp;