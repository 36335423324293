import React, { useRef, useEffect, useState } from 'react';
import ScrollAnim from 'rc-scroll-anim';
import { Form, Skeleton } from 'antd';
import { whiteSpace } from './../../../constant/whitespace/WhiteSpace';
import { connect } from 'react-redux';
import JobForm from './../home/jobs/form/JobForm';
import { MenuSource } from './../../../source/validation/menu/MenuValidation';
import { useParams } from "react-router-dom";
import { ServiciosService } from "../../../service"
import { MenuMultiple } from './../../../source/component/menu/MenuMultiple';

function ProductoDetalle(params) {
  const { idServicio } = useParams();
  const [servicioSelect, setServicioSelect] = React.useState({
    descripcionLarga: "descripcionLarga",
    nombre: "PUBLICIDAD Y PROMOCIÓN"
  });
  const [loadign, setLoading] = React.useState(true);
  const Links = ScrollAnim.Link;
  const [form] = Form.useForm();
  const {
    isMobile,
    current,
    itemMenu,
    setItemMenu
  } = params;
  const scrollTo = current && itemMenu.filter((s) => s.name === current) ? itemMenu.filter((s) => s.name === current)[0] : false;
  const event = useRef(null);
  const responseInfo = (res) => {
  }


  const getServicio = () => {

    ServiciosService.getServicioById(idServicio).then((res) => {
      setServicioSelect(res.data());

      setLoading(false)

    })

  }
  const children = [
    <Links
      key={0}
      to={`${scrollTo ? scrollTo.name : whiteSpace(MenuSource.SERVICE)}`}
      offsetTop={50}
    >
      <div ref={event}></div>

    </Links>,

    <JobForm
      isMobile={isMobile}
      form={form}
      response={responseInfo}
      Jbsimg1={servicioSelect}
    ></JobForm>
  ];
  useEffect(
    (async) => {
      event.current.click();
      setItemMenu(MenuMultiple.jobs);
      getServicio()
    },
    [setItemMenu]
  );

  return children;



}

const mapStateToProps = (state) => ({
  current: state.current,
  linkedin: state.linkedin,
  response: state.response,
});

const mapDispatchToProps = (dispatch) => ({
  handleCurrent(value) {
    dispatch({
      type: 'Add_Current',
      value,
    });
  },

  handleLinkedin(value) {
    dispatch({
      type: 'Add_Linkedin',
      value,
    });
  },
  handleResponse(value) {
    dispatch({
      type: 'Add_Response',
      value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductoDetalle);
