import firebase from "./Firebase";
const db = firebase.firestore();

class ServiciosService {
  getAllServicios = (observer) => {
    
    return db
      .collection("Servicios")
      .onSnapshot(observer);
  };
  getServicioById = (IdServicio) => {
    return db
      .collection("Servicios")
      .doc(IdServicio)
      .get();
  };
}

export default new ServiciosService();