import { ValidIdentification } from './../resource/ValidIdentification';
import { validRuc } from '../resource/ValidRuc';

export function asyncIdentification(value) {
  if (value) return ValidIdentification(value);
  return false;
}

export function asyncRuc(value) {
  if (value) return validRuc(value);
  return false;
}
