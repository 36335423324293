import React from 'react';
import AboutUsSection from './section/AboutSection';

function AboutUs(params) {
  const { isMobile } = params;

  const children = [
    <AboutUsSection key={0} isMobile={isMobile}></AboutUsSection>,
  ];

  return <div>{children}</div>;
}
export default AboutUs;
