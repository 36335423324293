import React from 'react';
import { Button } from 'antd';
import { PageStyle } from '../../source/style/page/PageStyle';
import { PageValidation } from '../../source/validation/page/PageValidation';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export function ButtonActionModal(params) {
  const { item, onclick } = params;
  return (
    <div {...PageStyle.padding0}>
      <Button onClick={() => onclick(item)} type="primary" >
        {PageValidation.readMore}
      </Button>
    </div>
  );
}
export function ButtonAction(params) {
  const { item, onclick } = params;
  return (
    <div {...PageStyle.padding0}>
      <Button onClick={() => onclick(item)} type="primary">
        {PageValidation.abrirmaps}
      </Button>
    </div>
  );
}
export function ButtonNextBack(params) {
  const {
    element,
    gallery,
    position,
    backClick,
    nextClick,
    nextGallery,
    nextPosition,
    backPosition,
  } = params;
  const isNext = gallery.position !== gallery?.nextback?.length - 1;
  return (
    <div>
      {element?.caracteristics[position]?.backButton && !gallery.active && (
        <Button
          type="text"
          {...element?.caracteristics[position]?.backButtonStyle}
          onClick={gallery.position !== 0 ? backPosition : backClick}
        >
          <LeftOutlined />
          Atrás
        </Button>
      )}
      {element?.caracteristics[position]?.nextButton && !gallery.nextback && (
        <Button type="link" onClick={nextClick}>
          Siguiente <RightOutlined />
        </Button>
      )}
      {gallery.images && !gallery.active && !gallery.nextback && (
        <Button type="link" onClick={nextGallery}>
          Siguiente <RightOutlined />
        </Button>
      )}
      {gallery?.nextback && (
        <Button type="link" onClick={isNext ? nextPosition : nextGallery}>
          Siguiente <RightOutlined />
        </Button>
      )}
    </div>
  );
}
