import { PageValidation } from '../../../validation/page/PageValidation';
import { PageImage } from './../../../image/PageImage';
export const ClientSource = {
  title: {
    content: `Dónde encontrarnos`,
    align: PageValidation.center,
  },
  colvalue: { xs: 12, sm: 6, md: 6, lg: 6 },
  style: { height: PageValidation.var160 },
  client: [
    {
      id: 0,
      tab: `España`,
      source: [
        {
          id: 0,
          title: `Sector AFS`,
          gallery: PageImage.client.gallery1,
          gallery1:[]
        },
      ],
    },
   
  ],
};
