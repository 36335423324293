import React from 'react';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { Link } from 'react-router-dom';
import { PageAnimation } from './../../source/animation/page/PageAnimation';
import { PageStyle } from './../../source/style/page/PageStyle';
import { PageDetail096RSMBS } from '../paragraph/ParagraphFunction';
import ScrollAnim from 'rc-scroll-anim';
import { Button, Row, Col } from 'antd';
import { PageDetailCS084C } from './../paragraph/ParagraphFunction';
import { useHistory } from "react-router-dom";

const LinkScroll = ScrollAnim.Link;
export const MenuIconPage = {
  alt: {
    option1: 'AMAROK',
  },
  menu: {
    icon: [<em key="em1" />, <em key="em2" />, <em key="em3" />],
  },
};

export function ListItems(params) {
  const { item, color, align, onFocus } = params;
  return (
    <QueueAnim key={0} leaveReverse>
      {item.map((item, index) => (
        <TweenOne
          key={index}
          animation={PageAnimation.animation4}
          style={PageStyle.anim1}
        >
          {item.url && (
            <Link to={item.url}>
              <Button type="text">
                <PageDetail096RSMBS
                  value={item.text}
                  color={color}
                  align={align}
                ></PageDetail096RSMBS>
              </Button>
            </Link>
          )}
          {!item.url && (
            <LinkScroll to={item.name} onFocus={onFocus}>
              <Button type="text">
                <PageDetail096RSMBS
                  value={item.text}
                  color={color}
                  align={align}
                ></PageDetail096RSMBS>
              </Button>
            </LinkScroll>
          )}
        </TweenOne>
      ))}
    </QueueAnim>
  );
}
export function ListItemsPl(params) {
  const { item, color, align, onFocus } = params;
  let history = useHistory();
  // let navigate = useNavigate();

  const onClick = (items) => {
    console.error(items.url)
    console.error(history)
    history.replace(items.url);


  }
  return (
    <QueueAnim key={0} >
      {item.map((items, index) => (
        <TweenOne
          key={index}
          animation={PageAnimation.animation4}
          style={PageStyle.anim1}
        >
          <Button type="text" onClick={() => onClick(items)}>
            <PageDetail096RSMBS
              value={items.text}
              color={color}
              align={align}
            ></PageDetail096RSMBS>
          </Button>
        </TweenOne>
      ))}
    </QueueAnim>
  );
}
/**List Item sin Link */
export function ListItemsWL(params) {
  const { item, color, align } = params;
  return (
    <QueueAnim key={0} leaveReverse>
      {item.map((elem, index) => (
        <TweenOne
          key={index}
          animation={PageAnimation.animation4}
          style={PageStyle.anim1}
        >
          <Row gutter={[8]}>
            <Col span={2}>{elem.img && elem.img}</Col>
            <Col span={22}>
              <PageDetailCS084C
                value={elem.text}
                color={color}
                align={align}
              ></PageDetailCS084C>
            </Col>
          </Row>
        </TweenOne>
      ))}
    </QueueAnim>
  );
}
