export const CarouselStyle = {
  span: {
    style: {
      backgroundSize: 'contain',
      backgroundColor: '#ffffff',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  spanCover: {
    style: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  spanIconTopRigth: {
    style: {
      backgroundSize: '30px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '90% 20%',
    },
  },
};
