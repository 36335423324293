import React from 'react';
import HeaderMenu from './HeaderMenu';

function Header(params) {
  const { isMobile, itemMenu } = params;

  const children = [
    <HeaderMenu
      key={0}
      isMobile={isMobile}
      itemMenu={itemMenu}
    ></HeaderMenu>
  ];

  return <div>{children}</div>;
}

export default Header;
