import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import ScrollAnim from 'rc-scroll-anim';
import { Link } from 'react-router-dom';

const { Item } = Menu;
const LinkScroll = ScrollAnim.Link;
function HeaderItems(params) {
  const {
    phoneOpen,
    current,
    handleCurrent,
    itemMenu,
    theme,
    mode,
    menustyle,
  } = params;

  const onClick = (e) => {
    handleCurrent(e.key);
  };

  const onFocus = (e) => {
    handleCurrent(e.to);
  };
  return (
    <Menu
      key="menuItem"
      selectedKeys={[current]}
      onClick={onClick}
      {...theme}
      {...mode}
      {...menustyle}
    >
      {phoneOpen &&
        itemMenu?.map((item) => (
          <Item key={item.name}>
            {!item.url && (
              <LinkScroll to={item.name} onFocus={onFocus}>
                {item.text}
              </LinkScroll>
            )}
            {item.url && <Link to={item.url}>{item.name}</Link>}
          </Item>
        ))}
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  current: state.current,
});

const mapDispatchToProps = (dispatch) => ({
  handleCurrent(value) {
    dispatch({
      type: 'Add_Current',
      value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderItems);
