import { whiteSpace } from './../../script/caracter/CaracterScript';
import { MenuSource } from './../validation/menu/MenuValidation';

export const RouteUrl = {
  HOME: '/',
  SERVICIO: `/${whiteSpace(MenuSource.SERVICE)}/:idServicio`,
  SERVICIO2: `/${whiteSpace(MenuSource.SERVICE)}`,

  DOCUMETOS: `/${whiteSpace(`documento`)}/:idDocumento`,

};

export const Method = {
  GET: 'GET',
  POST: 'POST',
};
