import { createStore } from 'redux';
import { LoadState } from './Localstorage';
const initialState = LoadState();

const Reducer = (state = initialState, action) => {
  if (action.type === 'Add_Current') {
    return {
      ...state,
      current: action.value,
    };
  }

  if (action.type === 'Add_Scroll') {
    return {
      ...state,
      scroll: action.value,
    };
  }

  if (action.type === 'Add_Linkedin') {
    return {
      ...state,
      linkedin: action.value,
    };
  }

  if (action.type === 'Add_Response') {
    return {
      ...state,
      response: action.value,
    };
  }

  return state;
};

export default createStore(Reducer);
